import { useState, useEffect, useRef, useMemo } from "react";
export const useMempoolWebSocket = () => {
  const [message, setMessage] = useState(null);
  const webSocketRef = useRef(null);
  const url = "wss://mempool.space/api/v1/ws";
  const memoizedUrl = useMemo(() => url, []);
  const onOpenActions = useMemo(() => [{ action: "want", data: ["blocks", "stats", "mempool-blocks"] }, { action: "init" }], []);
  const reconnect = () => {
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
  };
  useEffect(() => {
    let keepAliveInterval;
    const connectWebSocket = () => {
      webSocketRef.current = new WebSocket(memoizedUrl);
      webSocketRef.current.onopen = () => {
        console.log("WebSocket connected:");
        if (webSocketRef.current.readyState === WebSocket.OPEN) {
          onOpenActions.forEach((action) => {
            webSocketRef.current.send(JSON.stringify(action));
          });

          keepAliveInterval = setInterval(() => {
            if (webSocketRef.current.readyState === WebSocket.OPEN) {
              webSocketRef.current.send('ping');
            }
          }, 9000); // Send a ping message
        }
      };

      webSocketRef.current.onmessage = (event) => {
        setMessage(event.data);
      };

      webSocketRef.current.onerror = (event) => {
        // console.error("WebSocket error:", event);
      };

      webSocketRef.current.onclose = (event) => {
        // console.log("WebSocket connection closed:", event);
        // console.log("Reason:", event.reason);
        clearInterval(keepAliveInterval);
        setTimeout(() => {
          // console.log("Attempting to reconnect...");
          connectWebSocket();
        }, 9000); // Change to 9 seconds or a higher value
      };
    };
    connectWebSocket();
    return () => {
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
      clearInterval(keepAliveInterval);
    };
  }, [memoizedUrl, onOpenActions]);
  return { message, reconnect };
};
