// look at 'loading'
import { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { CustomSpinner } from './CustomSpinner';
import { Select, Box } from '@chakra-ui/react';

export const MarketCapTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [chartData, setChartData] = useState([]);

  const chartRef = useRef(null);

  const [latestMarketCap, setLatestMarketCap] = useState(null);
  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      const allData = data.bcprice.values.map((price) => ({
        x: new Date(price.x * 1000),
        y: price.y,
      }));

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 180,
        '3m': 90,
        '7d': 7,
        '2d': 2,
      };

      const filteredData = allData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData(filteredData);
      setLatestMarketCap(filteredData[filteredData.length - 1].y);
      setLoading(false);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.length > 0) {
      const formatValue = (value) => {
        const trillion = 1e12;
        const billion = 1e9;
        const marketCap = value * 21000000; // Multiply by 21 million for market cap
      
        if (marketCap >= trillion) {
          return (
            '$' +
            (marketCap / trillion).toLocaleString('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }) +
            ' T'
          );
        } else if (marketCap >= billion) {
          return (
            '$' +
            (marketCap / billion).toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }) +
            ' B'
          );
        } else {
          return '$' + marketCap.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      };


      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -12,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        annotations: {
          yaxis: [
            {
              y: latestMarketCap,
              borderColor: '#FFA500',
              borderWidth: 0,
              fillColor: '#FFA500',
              label: {
                borderColor: '#FFA500',
                borderWidth: 2,
                style: {
                  color: '#000000',
                  background: '#FFA500',
                  fontWeight: 'bold',
                  fontSize: '13px',
                },
                text: formatValue(latestMarketCap),
                offsetY: 0,
                offsetX: 5,
                textAnchor: 'start',
              },
            },
          ],
        },
        grid: {
          borderColor: '#555555',
          position: 'back',
        },
        series: [
          {
            name: 'Bitcoin Market Cap',
            data: chartData,
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'BTC Market Cap (USD)',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: formatValue,
              style: {
                colors: '#999',
              },
            },
          },
        ],
        colors: ['#ffa500'],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
        },
      };

      const chart = new ApexCharts(
        chartRef.current,
        options
      );
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, latestMarketCap]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="100px"
          mb={0}
        >
          <option value="3y">3 Years</option>
          <option value="2y">2 Years</option>
          <option value="1y">1 Year</option>
          <option value="6m">6 Months</option>
          <option value="3m">3 Months</option>
          <option value="7d">7 Day</option>
          <option value="2d">2 Day</option>
        </Select>
        <Box flexGrow={1} minHeight='337px'>
          {loading ? <CustomSpinner /> : <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />}
        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  }
};