import React, { useState, useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  FormControl,
  FormLabel,
  useColorMode
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoinSign } from '@fortawesome/free-solid-svg-icons';

const fetchBitcoinPriceCalc = async (currency) => {
  const response = await fetch(
    `https://api.coindesk.com/v1/bpi/currentprice/${currency}.json`
  );
  const data = await response.json();
  return data.bpi[currency].rate_float;
};

const currencySymbols = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  BGN: 'лв',
  CAD: 'C$',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: '¥',
  COP: 'COL$',
  CZK: 'Kč',
  DKK: 'kr',
  EGP: 'E£',
  HKD: 'HK$',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  KRW: '₩',
  MYR: 'RM',
  MXN: 'Mex$',
  NGN: '₦',
  NOK: 'kr',
  NZD: 'NZ$',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  RON: 'lei',
  SEK: 'kr',
  SGD: 'S$',
  THB: '฿',
  TRY: '₺',
  VND: '₫',
  ZAR: 'R',
};

const formatFiatAmount = (amount) => {
  return parseFloat(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const convertCryptoToAmount = (cryptoAmount, bitcoinPriceCalc) => {
  const sanitizedValue = parseFloat(cryptoAmount.replace(/,/g, ''));
  if (!isNaN(sanitizedValue)) {
    const convertedAmount = (sanitizedValue * bitcoinPriceCalc).toFixed(2);
    return formatFiatAmount(convertedAmount);
  } else {
    return '';
  }
};

export const BitcoinCalculator = ({ isOpen, onClose }) => {
  const breakpointValue = useBreakpointValue({ base: 'column', md: 'column' });
  const gapValue = useBreakpointValue({ base: 4, md: 4 });
  const [cryptoAmount, setCryptoAmount] = useState('1');
  const [satsAmount, setSatsAmount] = useState('100000000');
  const [cryptoCurrency, setCryptoCurrency] = useState('BTC');
  const [fiatCurrency, setFiatCurrency] = useState('USD');
  const [bitcoinPriceCalc, setBitcoinPriceCalc] = useState(0);
  const [fiatAmount, setFiatAmount] = useState('');

  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  useEffect(() => {
    const updateBitcoinPriceCalc = async () => {
      const price = await fetchBitcoinPriceCalc(fiatCurrency);
      setBitcoinPriceCalc(price);
      setFiatAmount(convertCryptoToAmount(cryptoAmount, price));
    };

    updateBitcoinPriceCalc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiatCurrency]);

  const handleCryptoAmountChange = (e) => {
    const value = e.target.value;
    setCryptoAmount(value);

    const satsValue = Math.floor(value * 100000000);
    setSatsAmount(satsValue.toString());
    setFiatAmount(convertCryptoToAmount(value, bitcoinPriceCalc));
  };

  const handleSatsAmountChange = (e) => {
    const value = e.target.value;
    setSatsAmount(value);

    const btcValue = (value / 100000000).toFixed(8);
    setCryptoAmount(btcValue);
    setFiatAmount(convertCryptoToAmount(btcValue, bitcoinPriceCalc));
  };


  const handleFiatAmountChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = parseFloat(value.replace(/,/g, ''));

    if (!isNaN(sanitizedValue)) {
      const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d)(\d{2})(?!\d))/g, ',');
      setFiatAmount(formattedValue);
      const convertedAmount = (sanitizedValue / bitcoinPriceCalc).toFixed(8);
      setCryptoAmount(convertedAmount);

      const satsValue = Math.floor(convertedAmount * 100000000);
      setSatsAmount(satsValue.toString());
    } else {
      setFiatAmount('');
      setCryptoAmount('');
      setSatsAmount('');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
        <ModalHeader>Bitcoin Calculator</ModalHeader>
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody>
          <Flex width="100%" direction={breakpointValue} alignItems="flex-end" gap={gapValue}>
            <FormControl>
              <FormLabel>Bitcoin</FormLabel>
              <InputGroup>
                <InputLeftAddon><FontAwesomeIcon icon={faBitcoinSign} /></InputLeftAddon>
                <Input type="number" value={cryptoAmount} onChange={handleCryptoAmountChange} borderWidth="1.5px" fontSize={'16px'} />
                <InputRightElement width="auto">
                  <Select value={cryptoCurrency} onChange={(e) => setCryptoCurrency(e.target.value)} icon={'none'}>
                    <option value="BTC">BTC</option>
                  </Select>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl>
              <FormLabel>Sats</FormLabel>
              <InputGroup>
                <InputLeftAddon fontSize={16}>ṩ</InputLeftAddon>
                <Input type="number" value={satsAmount} onChange={handleSatsAmountChange} borderWidth="1.5px" fontSize={'16px'} />
                <InputRightElement width="auto">
                  <Select value={cryptoCurrency} onChange={(e) => setCryptoCurrency(e.target.value)} icon={'none'}>
                    <option value="Sats">Sats</option>
                  </Select>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl ml={[0, 4]} mt={[4, 0]}>
              <FormLabel>Conversion</FormLabel>
              <InputGroup>
                <InputLeftAddon>{currencySymbols[fiatCurrency]}</InputLeftAddon>
                <Input
                  type="text"
                  inputMode="decimal"
                  pattern="\d{1,3}(,\d{3})*(\.\d+)?"
                  value={fiatAmount}
                  onChange={handleFiatAmountChange}
                  borderWidth="1.5px"
                  fontSize={'16px'}
                />

                <InputRightElement width="auto" borderWidth="2px" borderRadius="6px" >
                  <Select value={fiatCurrency} onChange={(e) => setFiatCurrency(e.target.value)}>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="JPY">JPY</option>
                    <option value="AED">AED</option>
                    <option value="AFN">AFN</option>
                    <option value="ALL">ALL</option>
                    <option value="AMD">AMD</option>
                    <option value="BGN">BGN</option>
                    <option value="CAD">CAD</option>
                    <option value="CHF">CHF</option>
                    <option value="CLP">CLP</option>
                    <option value="CNY">CNY</option>
                    <option value="COP">COP</option>
                    <option value="CZK">CZK</option>
                    <option value="DKK">DKK</option>
                    <option value="EGP">EGP</option>
                    <option value="HKD">HKD</option>
                    <option value="IDR">IDR</option>
                    <option value="ILS">ILS</option>
                    <option value="INR">INR</option>
                    <option value="JPY">JPY</option>
                    <option value="KRW">KRW</option>
                    <option value="MYR">MYR</option>
                    <option value="MXN">MXN</option>
                    <option value="NOK">NOK</option>
                    <option value="NZD">NZD</option>
                    <option value="PHP">PHP</option>
                    <option value="PKR">PKR</option>
                    <option value="PLN">PLN</option>
                    <option value="RON">RON</option>
                    <option value="SEK">SEK</option>
                    <option value="SGD">SGD</option>
                    <option value="THB">THB</option>
                    <option value="TRY">TRY</option>
                    <option value="VND">VND</option>
                    <option value="ZAR">ZAR</option>
                  </Select>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
