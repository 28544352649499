import { Flex, Link, useColorMode } from '@chakra-ui/react';
import { faCalculator, faSun, faMoon, faCalendarAlt, faChartLine, faBell } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { ThemedNavIcon } from '../../../theme/ThemedNavIcon';
import { TradingViewModal } from '../../data-display/TradingViewModal';
import { NotificationModal } from '../../data-display/NotificationModal';
import { BitcoinCalculator } from '../../data-display/BitcoinCalculator';
import { useState, useEffect } from 'react';

export const AppNavIcons = () => {
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();
  const [isTradingViewModalOpen, setIsTradingViewModalOpen] = useState(false);
  const [isBitcoinCalculatorOpen, setIsBitcoinCalculatorOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const ENABLE_NOTIFICATION = false; // Switch to false to disable notification bell icon

  const handleOpenTradingViewModal = () => {
    setIsTradingViewModalOpen(true);
  }

  const handleCloseTradingViewModal = () => {
    setIsTradingViewModalOpen(false);
  }

  const handleOpenBitcoinCalculator = () => {
    setIsBitcoinCalculatorOpen(true);
  }

  const handleCloseBitcoinCalculator = () => {
    setIsBitcoinCalculatorOpen(false);
  }

  const handleOpenNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };

  const handleCloseNotificationModal = () => {
    setIsNotificationModalOpen(false);
  };

  const saveColorModeInLocalStorage = (mode) => {
    localStorage.setItem('colorMode', mode);
  }

  const toggleAndSaveColorMode = () => {
    toggleColorMode();
    saveColorModeInLocalStorage(colorMode === 'dark' ? 'light' : 'dark');
  }

  useEffect(() => {
    const storedColorMode = localStorage.getItem('colorMode');
    if (storedColorMode) {
      setColorMode(storedColorMode);
    } else {
      setColorMode('dark');
    }
  }, [setColorMode]);

  return (
    <Flex gap={{ base: 6, md: 6 }}>
      {ENABLE_NOTIFICATION && (
        <Link onClick={handleOpenNotificationModal}>
          <ThemedNavIcon icon={faBell} fontSize={{ base: "18px", md: "20px" }} color="orange" />
        </Link>
      )}
      <Link onClick={handleOpenTradingViewModal}>
        <ThemedNavIcon icon={faChartLine} fontSize={{ base: "18px", md: "20px" }} />
      </Link>
      <Link onClick={handleOpenBitcoinCalculator}>
        <ThemedNavIcon icon={faCalculator} fontSize={{ base: "18px", md: "20px" }} />
      </Link>
      <Link href='https://timechaincalendar.com' target="_blank" display={{ base: "none", sm: "inline" }}>
        <ThemedNavIcon icon={faCalendarAlt} fontSize={{ base: "18px", md: "20px" }} />
      </Link>
      <Link href="https://twitter.com/TimechainStats" target="_blank" display={{ base: "none", sm: "inline" }}>
        <ThemedNavIcon icon={faTwitter} fontSize={{ base: "18px", md: "20px" }} />
      </Link>
      <Link onClick={toggleAndSaveColorMode}>
        <ThemedNavIcon
          icon={colorMode === 'dark' ? faSun : faMoon}
          fontSize={{ base: "18px", md: "20px" }}
        />
      </Link>
      <TradingViewModal isOpen={isTradingViewModalOpen} onClose={handleCloseTradingViewModal} />
      <BitcoinCalculator 
        isOpen={isBitcoinCalculatorOpen} 
        onClose={handleCloseBitcoinCalculator} 
      />
      <NotificationModal isOpen={isNotificationModalOpen} onClose={handleCloseNotificationModal} />
    </Flex>
  );
};
