import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MiningTileDifficulty = ({ data, formatLargeNumber, animate }) => {
  const numberValue = data
    ? `${formatLargeNumber(data.difficulty, 4, false) ?? "Loading..."}`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Difficulty" value={numberValue} />
    </ThemedFlex>
  );
};
