import { Flex, useColorMode, useBreakpointValue } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react';
import { AppLogo } from './AppLogo'
import { AppNavIcons } from './AppNavIcons'
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { ThemedTextHeader } from '../../../theme/ThemeTextHeader';
// import { DonationsModal } from '../../data-display/DonationsModal';
// import { ThemedDonateButtonSm } from '../../../theme/ThemedDonateButtonSm';
// import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

export const AppHeader = () => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? 'primary.700' : 'blue.500';
  const borderBottomColor = colorMode === 'dark' ? 'gray.500' : 'blue.300';
  const headerIconColor = colorMode === 'dark' ? '#777' : '#fff';
  const isSmallish2Screen = useBreakpointValue({ base: true, xl: false });
  const isSmallishScreen = useBreakpointValue({ base: true, lg: false });
  const [isInitialRender, setIsInitialRender] = useState(true);
  // const [isDonationsModalOpen, setDonationsModalOpen] = useState(false);

  // const openDonationsModal = () => setDonationsModalOpen(true);
  // const closeDonationsModal = () => setDonationsModalOpen(false);
  // const donateForm = () => {
  //   closeDonationsModal();
  // };


  // const calculateTimeLeft = () => {
  //   const now = new Date();
  //   const targetTime = new Date(now.getTime() + (1 * 60 * 60 * 1000)); // Add 1 hours in milliseconds
  //   const difference = targetTime - now;
  
  //   let timeLeft = {};
  
  //   if (difference > 0) {
  //     timeLeft = {
  //       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //       minutes: Math.floor((difference / 1000 / 60) % 60),
  //       seconds: Math.floor((difference / 1000) % 60),
  //     };
  //   }
  
  //   return timeLeft;
  // };

  // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);
  // });

  // const timerComponents = [];

  // Object.keys(timeLeft).forEach((interval) => {
  //   if (!timeLeft[interval]) {
  //     return;
  //   }

  //   timerComponents.push(
  //     <span>
  //       {timeLeft[interval]} {interval}{" "}
  //     </span>
  //   );
  // });


  const randomElements = [

    (
      <ThemedTextHeader>
        Thanks for your support
        <Link
          href="https://www.youtube.com/channel/UCVfMv5xEfrafk1rSthJ0t9g"
          target='_blank'
          mx="1"
          textDecoration={'underline'}
        >
          Pleb Underground
        </Link>
        <FontAwesomeIcon
          icon={faYoutube}
          size={'lg'}
          color={headerIconColor}
        />
      </ThemedTextHeader>
    ),

    // (
    //   <ThemedTextHeader>
    //     Thanks for your support
    //     <Link
    //       href="https://twitter.com/hgxbitcoin"
    //       target='_blank'
    //       mx="1"
    //       textDecoration={'underline'}
    //     >
    //       @hgxbitcoin

    //     </Link>
    //     <FontAwesomeIcon
    //       icon={faTwitter}
    //       size={'lg'}
    //       color={headerIconColor}
    //     />
    //   </ThemedTextHeader>
    // ),

    // (
    //   <ThemedTextHeader>
    //     Thanks for your support
    //     <Link
    //       href="https://twitter.com/S0cratesz"
    //       target='_blank'
    //       mx="1"
    //       textDecoration={'underline'}
    //     >
    //       @S0cratesz

    //     </Link>
    //     <FontAwesomeIcon
    //       icon={faTwitter}
    //       size={'lg'}
    //       color={headerIconColor}
    //     />
    //   </ThemedTextHeader>
    // ),

    // (
    //   <ThemedTextHeader>
    //     Help keep TimechainStats running!
    //     <ThemedDonateButtonSm onClick={openDonationsModal}><DonationsModal isOpen={isDonationsModalOpen} onClose={closeDonationsModal} donateForm={donateForm} />
    //       <FontAwesomeIcon icon={faBtc} style={{ marginRight: (isSmallishScreen || isSmallish2Screen) ? '0' : '0.5rem' }} size={'lg'} />Donate</ThemedDonateButtonSm>
    //   </ThemedTextHeader>
    // ),

    // (
    //   <ThemedTextHeader>
    //     Learn about Bitcoin!
    //     <Link
    //       href="https://btclexicon.com"
    //       target='_blank'
    //       mx="1"
    //       textDecoration={'underline'}
    //     >
    //       BTCLexicon.com
    //     </Link>
    //     <FontAwesomeIcon
    //       icon={faBookOpen}
    //       size={'lg'}
    //       color={headerIconColor}
    //     />
    //   </ThemedTextHeader>
    // ),

    // (
    //   <ThemedTextHeader>
    //     Only 1 hour left to vote for TimechainStats! -
    //     <Link
    //       href="https://twitter.com/w_s_bitcoin/status/1778508512496075155"
    //       target='_blank'
    //       mx="1"
    //       textDecoration={'underline'}
    //       title="Click here to vote for TimechainStats on the Twitter poll"
    //     >
    //       VOTE NOW
    //     </Link>
    //     <FontAwesomeIcon
    //       icon={faVoteYea}
    //       size={'lg'}
    //       color={headerIconColor}
    //     />
    //   </ThemedTextHeader>
    // ),

  ];

  const [showElement, setShowElement] = useState(() => Math.floor(Math.random() * randomElements.length)); // Get random index on first render

  useEffect(() => {

    const updateRandomElement = () => {
      setShowElement(Math.floor(Math.random() * randomElements.length));
    };

    const intervalId = setInterval(updateRandomElement, 300000); // Updater

    return () => clearInterval(intervalId); // Cleanup function to clear interval
  }, [randomElements.length]);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  return (
    <Flex
      bg={bgColor}
      h="58px"
      borderBottomWidth="1px"
      borderBottomColor={borderBottomColor}
      py="5px"
      px="20px"
      align="center"
      justify="space-between"
      position="sticky"
      top="0"
      zIndex="1000"
      style={{
        backgroundColor: isInitialRender ? '#121212' : undefined,
        borderBottomColor: isInitialRender ? '#777' : undefined
      }}
    >
      <AppLogo />

      {!isSmallishScreen && !isSmallish2Screen && (
        randomElements[showElement]
      )}

      <AppNavIcons />
    </Flex>
  )
}
