import { useEffect, useState, useRef } from 'react';
import { Select, Box } from '@chakra-ui/react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { CustomSpinner } from './CustomSpinner';

export const HashRatePriceTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [chartData, setChartData] = useState({ hashRate: [], price: [] });

  const chartRef = useRef(null);

  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data) {
      const allHashRateData = data.hashrate.hashrates.map(({ timestamp, avgHashrate }) => ({
        x: new Date(timestamp * 1000),
        y: avgHashrate / 1e18,
      }));

      // Replace market_chart_3y with bcprice
      const allPriceData = data.bcprice ? data.bcprice.values.map((price) => ({
        x: new Date(price.x * 1000),
        y: price.y,
      })) : [];

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 180,
        '3m': 90,
        '7d': 7,
        '2d': 2,
      };

      const filteredHashRateData = allHashRateData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      const filteredPriceData = allPriceData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData({ hashRate: filteredHashRateData, price: filteredPriceData });
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.hashRate.length > 0 && chartData.price.length > 0) {
      const options = {
        chart: {
          offsetX: -12,
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        grid: {
          borderColor: '#555555',
          position: 'back',
          padding: {
            top: 0,
            right: -10,
            bottom: 0,
            left: 10
          },
        },
        series: [
          {
            name: 'Bitcoin Total Hash Rate (EH/s)',
            data: chartData.hashRate,
          },
          {
            name: 'BTC~USD Price',
            data: chartData.price,
          },
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: '#999',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            type: 'logarithmic',
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'Hash Rate (EH/s)',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: function (value) {
                return new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(value);
              },
              style: {
                colors: '#999',
              },
              offsetX: -20,
            },
          },
          {
            type: 'logarithmic',
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'BTC~USD Price',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: function (value) {
                const formattedValue = '$' + (value / 1000).toFixed(0) + 'k';
                return formattedValue;
              },
              style: {
                colors: '#999',
              },
            },
          },
        ],
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: function (value, { seriesIndex }) {
              const formattedValue = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: value < 1000 ? 2 : 0,
                maximumFractionDigits: 2,
              }).format(value);
              return seriesIndex === 1 ? '$' + formattedValue : formattedValue;
            },
          },
        },
      };
      const chart = new ApexCharts(
        chartRef.current,
        options
      );
      chart.render();
      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%', width: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="100px"
          mb={0}
        >
          <option value="3y">3 Years</option>
          <option value="2y">2 Years</option>
          <option value="1y">1 Year</option>
          <option value="6m">6 Months</option>
          <option value="3m">3 Months</option>
          <option value="7d">7 Day</option>
          <option value="2d">2 Day</option>
        </Select>
        <Box flexGrow={1} minHeight='337px'>
          <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%', width: '100%' }} />
        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  }
}
