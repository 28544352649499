import { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { Text, Select, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';
import { recentBlocksState } from '../../recoilState';
import { useRecoilValue } from 'recoil';

export const RewardsToFeesTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  formatLargeNumber,
}) => {
  const [chartData, setChartData] = useState([]);
  const recentBlocks = useRecoilValue(recentBlocksState);
  // console.error("WTF recentBlocks:", recentBlocks);

  const [selectedBlock, setSelectedBlock] = useState(recentBlocks[recentBlocks.length - 1]);

  const chartRef = useRef(null);

  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  const handleSelectedBlockChange = (event) => {
    const newSelectedBlock = recentBlocks.find((x) => String(x.height) === event.target.value)

    if (newSelectedBlock === undefined) {
      setSelectedBlock('24hr')
      return
    }
    setSelectedBlock(newSelectedBlock);
  };

  useEffect(() => {
    if (data && selectedBlock) {
      let formattedData

      if (selectedBlock === '24hr') {
        formattedData = [
          {
            name: 'Total Subsidy (sats)',
            // value: parseInt(data.rewards.totalReward),
            value: 45000000000,
          },
          {
            name: 'Total Fee (sats)',
            value: parseInt(data.rewards.totalFee),
          },
        ]
      } else {
        const actualReward = parseInt(selectedBlock.extras.reward) - parseInt(selectedBlock.extras.totalFees);
        formattedData = [
          {
            name: selectedBlock ? `Total Subsidy (sats)` : 'Loading...',
            value: actualReward,
          },
          {
            name: selectedBlock ? `Total Fees (sats)` : 'Loading...',
            value: parseInt(selectedBlock.extras.totalFees),
          },
        ];
      }
      setChartData(formattedData);
    }
  }, [data, selectedBlock]);

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'donut',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: 0,
            tools: {
              download: false,
            },
            padding: {
              bottom: 0,
            },
            color: '#78909C',
          },
        },
        labels: chartData.map(data => data.name),
        series: chartData.map(data => data.value),
        legend: {
          position: 'top',
          labels: {
            colors: '#999',
          },
        },
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        dataLabels: {
          dropShadow: {
            enabled: false
          },
          style: {
            colors: ['#121212'],
          },
        },
        stroke: {
          colors: [borderColor],
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: (value) => {
              return `${formatLargeNumber(value, 2)} sats`;
            },
          },
          title: {
            formatter: (seriesName) => {
              return seriesName;
            },
          },
        },
      };
      const chart = new ApexCharts(
        chartRef.current,
        options
      );
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, formatLargeNumber, borderColor]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />

        <Select
          onChange={handleSelectedBlockChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="150px"
          mt={2}
          mb={2}
        >
          {recentBlocks &&
            recentBlocks
              .slice(0, 7)
              .reverse()
              .map((block) => (
                <option key={block.height} value={block.height}>
                  {`Block: ${block.height.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </option>
              ))}
          {/* 144 Blocks option at the end */}
          <option value="24hr">144 Blocks</option>
        </Select>

        <Box flexGrow={1} minHeight='337px' pt={2}>
          <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
        </Box>

        {selectedBlock !== '24hr' && (
          <>
            <Text textAlign={'center'} fontSize={'12px'} mt={-4} mb={1} fontWeight={'bold'}>
              {selectedBlock ? `Total Fees ${formatLargeNumber(selectedBlock.extras.totalFees, 1, false)} sats` : 'Loading...'} &nbsp;•&nbsp; Subsidy 313 M sats/block
            </Text>
            <Text textAlign={'center'} fontSize={'12px'} mt={0} mb={2} fontWeight={'bold'}>
            {selectedBlock ? `Total Reward ${formatLargeNumber(selectedBlock.extras.reward, 1, false)} sats` : 'Loading...'} &nbsp;•&nbsp; {selectedBlock ? `${selectedBlock.extras.pool.name}` : 'Loading...'}
            </Text>
            {/* <Text textAlign={'center'} fontSize={'14px'} mt={1} mb={2}>
              {selectedBlock ? `Block ${selectedBlock.height} found by ${selectedBlock.extras.pool.name}` : 'Loading...'}
            </Text> */}
          </>
        )}
            <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  };
}
