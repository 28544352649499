import React, { useState, useEffect } from 'react';
import {
    Text,
    Center,
    useBreakpointValue,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BtcPayForm.css';
import { faHeart, faQrcode } from '@fortawesome/free-solid-svg-icons';

// Function component definition
export function BtcPayForm({ onClose }) {

    // Use Chakra UI's useBreakpointValue hook to set the width based on the breakpoint
    const width = useBreakpointValue({ base: '100%', md: '168px' });
    const textWidth = useBreakpointValue({ base: '100%', md: '70%' });

    // Use React Hooks to create and manage state for price
    const [price, setPrice] = useState(1);

    // Handles slider changes and updates price state
    const handleSliderChange = (event) => {
        setPrice(event.target.value);
    };

    // Handles price changes and updates price state
    const handlePriceChange = (event) => {
        let newPrice = parseInt(event.target.value.replace(/,/g, ''));
        if (isNaN(newPrice)) {
            newPrice = 1;
        } else if (newPrice < 1) {
            newPrice = 1;
        } else if (newPrice > 500000000) {
            newPrice = 500000000;
        }
        setPrice(newPrice);
    };

    // Format the price 
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // Handles form submission and sends an HTTP request
    const handleFormSubmit = (event) => {
        event.preventDefault();
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200 && this.responseText) {
                window.btcpay.appendInvoiceFrame(JSON.parse(this.responseText).invoiceId);
                onClose(); // Close the modal
            }
        };
        xhttp.open('POST', event.target.getAttribute('action'), true);
        xhttp.send(new FormData(event.target));
    };

    // Runs once on component mount and appends btcpay script
    useEffect(() => {
        if (!window.btcpay) {
            var script = document.createElement('script');
            script.src = "https://btcpay609804.lndyn.com/modal/btcpay.js";
            document.getElementsByTagName('head')[0].append(script);
        }
    }, []);

    // Optimized Function to render hearts
    const renderHearts = () => {
        const heartLevels = [
            { limit: 249999, hearts: 1 },
            { limit: 499999, hearts: 2 },
            { limit: 4999999, hearts: 3 },
            { limit: 99999999, hearts: 4 },
            { limit: 100000000, hearts: 5 },
            { limit: 500000000, hearts: 5 }
        ];


        let heartCount = 0;
        for (const level of heartLevels) {
            if (price <= level.limit) {
                heartCount = level.hearts;
                break;
            }
        }

        return (
            <>
                {Array(heartCount).fill(null).map((_, index) => (
                    <FontAwesomeIcon key={index} icon={faHeart} color={'orange'} />
                ))}
            </>
        );
    };

    return (
        <form method="POST" action="https://btcpay609804.lndyn.com/api/v1/invoices" className="btcpay-form btcpay-form--block" onSubmit={handleFormSubmit}>
            <input type="hidden" name="storeId" value="5R4jnTVxzbQgoN9WWQX2A99K7b1A8qMuS4A2hFz7dxfq" />
            <input type="hidden" name="jsonResponse" value="true" />
            <input type="hidden" name="checkoutDesc" value="TimechainStats Tips" />
            {/* <input type="hidden" name="notifyEmail" value="timechainstats@gmail.com" /> */}
            <input type="hidden" name="currency" value="SATS" />
            <div className="btcpay-custom-container">
                <input
                    className="btcpay-input-price"
                    type="text"
                    name="price"
                    min="1"
                    max="500000000"
                    step="1"
                    value={formatPrice(price)}
                    onChange={handlePriceChange}
                    style={{ width }}
                />
                <p style={{ fontSize: '12px', textAlign: 'center', marginTop: '3px' }}>SATS</p>
                <input
                    type="range"
                    className="btcpay-input-range"
                    min="1"
                    max="500000"
                    step="1"
                    value={price}
                    onChange={handleSliderChange}
                    style={{ width, marginBottom: '15px' }}
                />

                <Center mt={1} mb={2} flexDirection="column" gap={2}>
                    <Text fontSize={'sm'} width={textWidth}>Thank you for your support! {renderHearts()}</Text>
                </Center>

                {/* {(price > 1200000 && price < 75000000) && (
                    <Center mt={1} mb={2} flexDirection="column" gap={2}>
                        <Text fontSize={'sm'} width={'70%'}>Add a Twitter profile as a Top Contributor</Text>
                        <Button
                            as="a"
                            href="https://twitter.com/messages/compose?recipient_id=1312097935526633474"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="xs"
                            leftIcon={<FontAwesomeIcon icon={faTwitter} />}
                            bgColor="#1DA1F2"
                            color="#ffffff"
                            _hover={{ bgColor: 'orange' }}
                            _active={{ bgColor: '#1DA1F2' }}
                        >
                            Message @TimechainStats
                        </Button>
                    </Center>
                )}
                
                {price >= 75000000 && price <= 90000000 && (
                    <Center mt={1} mb={2} flexDirection="column" gap={2}>
                        <Text fontSize={'sm'} width={'70%'}>Add your company logo and a link<br /><span style={{ color: '#888', fontSize: '10px' }}> For up to 6-months</span></Text>
                        <Button
                            as="a"
                            href="https://twitter.com/messages/compose?recipient_id=1312097935526633474"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="xs"
                            leftIcon={<FontAwesomeIcon icon={faTwitter} />}
                            bgColor="#9B59B6"
                            color="#ffffff"
                            _hover={{ bgColor: 'orange' }}
                            _active={{ bgColor: '#1DA1F2' }}
                        >
                            Message @TimechainStats
                        </Button>
                    </Center>
                )}
                
                {price >= 90000000 && price <= 100000000 && (
                    <Center mt={1} mb={2} flexDirection="column" gap={2}>
                        <Text fontSize={'sm'} width={'70%'}>Add your company logo and a link<br /><span style={{ color: '#888', fontSize: '10px' }}> For up to 1-year</span></Text>
                        <Button
                            as="a"
                            href="https://twitter.com/messages/compose?recipient_id=1312097935526633474"
                            target="_blank"
                            rel="noopener noreferrer"
                            size="xs"
                            leftIcon={<FontAwesomeIcon icon={faTwitter} />}
                            bgColor="#9B59B6"
                            color="#ffffff"
                            _hover={{ bgColor: 'orange' }}
                            _active={{ bgColor: '#1DA1F2' }}
                        >
                            Message @TimechainStats
                        </Button>
                    </Center>
                )} */}

            </div>
            <button type="submit" className="submit" name="submit" style={{ minWidth: '168px', minHeight: '46px', backgroundColor: '#0f3b21', cursor: 'pointer' }} title="Pay with BTCPay Server, a Self-Hosted Bitcoin Payment Processor">
                <span style={{ color: '#fff', fontSize: '16px' }}> <FontAwesomeIcon icon={faQrcode} /> &nbsp;Request Invoice</span>
            </button>
        </form>
    );
}
