import { SingleDataValue } from '../data-display';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const DataTileCirculatingSupply = ({ data, formatLargeNumber, animate }) => {
  const circulatingSupply = data
    ? `${formatLargeNumber(data.circulating, 2, false)}`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Circ Supply" value={circulatingSupply} />
    </ThemedFlex>
  );
};
