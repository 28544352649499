import { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Text, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const RewardsToFeesBarTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  formatLargeNumber,
}) => {
  const [chartData, setChartData] = useState([]);

  const { colorMode } = useColorMode();

  const labelColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  useEffect(() => {
    if (data) {
      const formattedData = [
        {
          name: 'Total Reward (sats)',
          value: parseInt(data.rewards.totalReward),
        },
        {
          name: 'Total Fee (sats)',
          value: parseInt(data.rewards.totalFee),
        },
      ];
      setChartData(formattedData);
    }
  }, [data]);

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'bar',
          stacked: true,
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'inside',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [labelColor],
          },
          formatter: (value) => {
            return `${formatLargeNumber(value, 2)} sats`;
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: '#999',
          },
        },
        series: chartData.map(data => ({
          name: data.name,
          data: [data.value],
        })),
        xaxis: {
          categories: ['Rewards & Fees'],
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: {
          min: 0,
          labels: {
            style: {
              colors: '#999',
            },
            formatter: (value) => {
              if (value === null || value === undefined) {
                return '';
              }
              const formattedValue = formatLargeNumber(value, 0);
              return `$${formattedValue}`;
            },
          },
        },
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: (value) => {
              return `${formatLargeNumber(value, 2)} sats`;
            },
          },
          title: {
            formatter: (seriesName) => {
              return seriesName;
            },
          },
        },
      };
      const chart = new ApexCharts(
        document.querySelector('#chart10'),
        options
      );
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, formatLargeNumber, labelColor]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Box flexGrow={1} minHeight='337px'>
          <div id="chart10" style={{ height: '100%' }} />
        </Box>
        <Text textAlign={'center'} fontSize={'12px'} mt={1} mb={2}>Current Subsidy 313 M sats/block</Text>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  };
};
