import {
  Flex,
  Box,
  List,
  ListItem,
  Text,
  Tooltip,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { useState, useEffect, useRef, useCallback } from "react";
import { HighlightedDataPoint } from "./HighlightedDataPoint";

const DataRowLabel = ({ label, tooltipText }) => {
  const { colorMode } = useColorMode();
  const labelColor = colorMode === "dark" ? "gray.200" : "gray.500";

  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef();

  const closeTooltip = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleTouch = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("touchend", closeTooltip);
    } else {
      document.removeEventListener("touchend", closeTooltip);
    }

    return () => {
      document.removeEventListener("touchend", closeTooltip);
    };
  }, [isOpen, closeTooltip]);

  return (
    <Tooltip
      ref={tooltipRef}
      label={tooltipText}
      aria-label="Tooltip"
      fontSize="13px"
      lineHeight="14px"
      bg="#000"
      color="#fff"
      p="10px 20px"
      border="1px solid #fff"
      borderRadius="3px"
      boxShadow="0 0 4px #000"
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <Text
      as="span"
        fontSize="14px"
        lineHeight={"1.2em"}
        fontWeight="400"
        color={labelColor}
        className="unselectable"
        onClick={handleTouch}
      >
        {label}
      </Text>
    </Tooltip>
  );
};

const DataRowValue = ({ oldValue, newValue, value, displayValue, type }) => {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const defaultColor = colorMode === "dark" ? "gray.900" : "gray.500";
  const [textColor, setTextColor] = useState(defaultColor);

  useEffect(() => {
    if (oldValue && newValue > oldValue) {
      setTextColor(theme.colors.green[500]);
    } else if (oldValue && newValue < oldValue) {
      setTextColor(theme.colors.red[500]);
    } else {
      setTextColor(defaultColor);
    }
  }, [newValue, oldValue, theme, defaultColor]);

  return type === "link" ? (
    <Text
      color={textColor}
      transition="color 0.5s ease"
      fontSize="15px"
      lineHeight={"1.2em"}
      textAlign="right"
      fontWeight="700"
      fontFamily="'Ubuntu Mono', monospace"
      textDecoration="underline"
    >
      <a href={value} target="_blank" rel="noopener noreferrer">
        {displayValue}
      </a>
    </Text>
  ) : (
    <Text
      color={textColor}
      transition="color 0.5s ease"
      fontSize="15px"
      lineHeight={"1.2em"}
      textAlign="right"
      fontWeight="700"
      fontFamily="'Ubuntu Mono', monospace"
    >
      {newValue}
    </Text>
  );
};

export const DataTableRows = ({ rows }) => {
  const { colorMode } = useColorMode();
  const borderColor = colorMode === "dark" ? "gray.300" : "gray.101";

  const [prevRows, setPrevRows] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPrevRows(rows);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [rows]);

  return (
    <List mb="10px">
      {rows.map((row, index) => {
  const nextRowHighlighted = rows[index + 1]?.highlighted;
  return (
    <ListItem
      key={index}
      borderBottomWidth={
        row.highlighted || nextRowHighlighted ? 0 : 1
      }
      borderTopWidth={
        row.highlighted && index > 0 ? 0 : undefined
      }
      borderColor={borderColor}
    >
      {row.highlighted ? (
        <HighlightedDataPoint
          label={row.label}
          value={row.value}
        />
      ) : (
        <Flex h="40px" align="center">
          <Box flex={1}>
            <DataRowLabel
              label={row.label}
              tooltipText={row.tooltipText}
            />
          </Box>
          <Box flex={1}>
            <DataRowValue
              oldValue={prevRows[index]?.value}
              newValue={row.value}
              value={row.value}
              displayValue={row.displayValue}
              type={row.type}
            />
          </Box>
        </Flex>
      )}
    </ListItem>
  );
})}
    </List>
  );
};
