import { useState, useEffect } from 'react';
import { Text, Flex, Grid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useColorMode, useBreakpointValue } from '@chakra-ui/react';
import { SingleDataValue } from '../../data-display';
import { BitcoinPrice } from './BitcoinPrice';
import { ThemedLogoIcon } from '../../../theme/ThemedLogoIcon';
import { faBtc } from "@fortawesome/free-brands-svg-icons";
import { ThemedModalButton } from '../../../theme/ThemedModalButton';
import { CustomSpinner } from '../../data-display/CustomSpinner';
import { daysSince, daysLeft } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';
import { CurrencyModalButton } from '../../../theme/CurrencyModalButton';
import { createCurrencyConverter } from '../../../utils/currencyConversion.utils';
import { useRecoilState } from 'recoil';
import { selectedCurrencyState } from '../../../recoilState';

export const DataBanner = ({
  formatNumber,
  formatLargeNumber,
  lastMempoolBlockData,
  fees,
  mempoolInfo,
  data,
  bitcoinPrice,
}) => {

  const dataOptions = [
    { id: 'blockHeight', label: 'Block Height' },
    { id: 'hashRate7d', label: 'Hash Rate 7d' },
    { id: 'unconfirmedTxs', label: 'Unconfirmed' },
    { id: 'highFeePerVbyte', label: 'Fastest Fee' },
    { id: 'miningRevenue24h', label: 'Mining Rev 24h' },
    { id: 'satsPerDollar', label: 'Sats~Fiat' },
    { id: 'blocksToHalving', label: 'Blks to Halving' },
    { id: 'daysToHalving', label: 'Next Halving' },
    { id: 'blocksToDiffAdj', label: 'Blks to Diff Adj' },
    { id: 'marketCap', label: 'Market Cap' },
    { id: 'btcVol24H', label: 'Vol 24H' },
    { id: 'low24H', label: 'Low 24H' },
    { id: 'high24H', label: 'High 24H' },
    { id: 'changePercent24H', label: '% Chng 24H' },
    { id: 'change24H', label: '$ Chng 24H' },
    { id: 'change200d', label: '% Chng 200d' },
    { id: 'change1y', label: '% Chng 1Y' },
    { id: 'changeAth', label: '% from ATH' },
    { id: 'mempoolTotalSize', label: 'Mempool Size' },
    { id: 'flowInExchange', label: 'BTC Exch In' },
    { id: 'flowOutExchange', label: 'BTC Exch Out' },
    { id: 'assetAge', label: 'BTC Age (D)' },
    { id: 'avgUtxoAge', label: 'Avg UTXO Age' },
    { id: 'avgBlockTime', label: 'Avg Block Time' },
    { id: 'predictedNextDiff', label: 'Next Diff' },
    { id: 'newBlocks24h', label: 'New Blocks 24h' },
    { id: 'addressesOver1Btc', label: '1+ BTC Bags' },
    { id: 'silverToBtc', label: 'Silver~BTC' },
    { id: 'goldToBtc', label: 'Gold~BTC' },
    { id: 'bigMacToBtc', label: 'Big Macs~BTC' },
    { id: 'lightningChannels', label: 'LN Channels' },
    { id: 'lightningCapacity', label: 'LN Capacity' },
    { id: 'transactions24h', label: '# of TX 24h' },
    { id: 'avgTxValue', label: 'Avg TX Value' },
    { id: 'txAllTime', label: 'Total TXs' },
    { id: 'sixOneFive', label: '6.15 BTC Value' },
  ];

  const [conversionRates, setConversionRates] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useRecoilState(selectedCurrencyState);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [currentlySelectedId, setCurrentlySelectedId] = useState(null);


  useEffect(() => {
    if (data) {
      const newConversionRates = createCurrencyConverter(data);
      setConversionRates(newConversionRates);
    }
  }, [data]);

  const convertBitcoinPrice = (bitcoinPrice) => {
    if (selectedCurrency && conversionRates && conversionRates[selectedCurrency]) {
      return bitcoinPrice * conversionRates[selectedCurrency];
    }
    return bitcoinPrice;
  };

  const breakpoint = useBreakpointValue({ base: 'base', xs: 'xs', sm: 'sm', 'sm2': 'sm2', md: 'md', lg: 'lg', xl: 'xl', '2xl': '2xl' });

  const { colorMode } = useColorMode();
  const modalBgColor = colorMode === 'dark' ? '#121212' : 'white';
  const modalTextColor = colorMode === 'dark' ? 'white' : '#121212';
  const modalBorderColor = colorMode === 'dark' ? 'white' : '#121212';
  const itsUpToYou = colorMode === 'dark' ? '#353535' : '#b8b8b8';

  const onOpen = (id) => {
    setCurrentlySelectedId(id);
    setIsOpen(true);
  };
  const onClose = () => setIsOpen(false);

  const handleSwap = (newId) => {
    setSelectedData((prevData) => {
      const newData = [...prevData];
      const currentIndex = newData.findIndex((selectedId) => dataOptions[selectedId].id === currentlySelectedId);
      const newIndex = newData.findIndex((selectedId) => dataOptions[selectedId].id === newId);

      if (currentIndex !== -1 && newIndex !== -1) {
        [newData[currentIndex], newData[newIndex]] = [newData[newIndex], newData[currentIndex]];
      } else if (currentIndex !== -1) {
        newData[currentIndex] = dataOptions.findIndex((option) => option.id === newId);
      }

      return newData;
    });

    onClose();
  };

  useEffect(() => {
    const storedCurrency = localStorage.getItem('selectedCurrency');
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency);
    } else {
      setSelectedCurrency('usd');
    }
  }, [setSelectedCurrency]);

  useEffect(() => {
    if (selectedCurrency) {
      localStorage.setItem('selectedCurrency', selectedCurrency);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    const storedData = localStorage.getItem('selectedData');
    if (storedData) {
      setSelectedData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedData', JSON.stringify(selectedData));
  }, [selectedData]);

  const valueMap = {
    blockHeight: lastMempoolBlockData ? `${formatNumber(lastMempoolBlockData.height, 0)}` : 'Loading...',
    hashRate7d: data ? `~${formatLargeNumber(data.hashrate?.currentHashrate, 0, true)}H/s` : 'Loading...',
    unconfirmedTxs: mempoolInfo ? `~${formatLargeNumber((mempoolInfo.size), 0, false)} TX` : 'Loading...',
    highFeePerVbyte: fees ? `${formatNumber((fees.fastestFee), 0)} ṩ/vB` : 'Loading...',
    miningRevenue24h: data ? `~${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.mining_revenue_usd), 2, false)}` : 'Loading...',
    satsPerDollar: bitcoinPrice ? `${formatNumber((1 / convertBitcoinPrice(bitcoinPrice)) * 100000000, 0)}` : 'Loading...',
    blocksToHalving: lastMempoolBlockData ? `${formatNumber(1050000 - lastMempoolBlockData.height)}` : 'Loading...',
    daysToHalving: data ? `~${formatNumber(daysLeft(data.halvening_time), 0)} Days` : 'Loading...',
    blocksToDiffAdj: data && lastMempoolBlockData ? `${formatNumber((data.nextretarget - lastMempoolBlockData.height) + 1, 0)}` : 'Loading...',
    marketCap: data ? `${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.marketCapUsd), 2, false)}` : 'Loading...',
    btcVol24H: data ? `${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(data[`total_volume_${selectedCurrency}`], 2, false)}` : 'Loading...',
    low24H: data ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(convertBitcoinPrice(data.low_24hr), 0)}` : 'Loading...',
    high24H: data ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(convertBitcoinPrice(data.high_24hr), 0)}` : 'Loading...',
    changePercent24H: data ? `${formatNumber(data[`price_change_percentage_24h_in_currency_${selectedCurrency}`], 2)}%` : 'Loading...',
    change24H: data ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(data[`price_change_24h_in_currency_${selectedCurrency}`], 2)}` : 'Loading...',
    change200d: data ? `${formatNumber(data[`price_change_percentage_200d_in_currency_${selectedCurrency}`], 2)}%` : 'Loading...',
    change1y: data ? `${formatNumber(data[`price_change_percentage_1y_in_currency_${selectedCurrency}`], 2)}%` : 'Loading...',
    changeAth: data ? `${formatNumber(data[`ath_change_percentage_${selectedCurrency}`], 2)}%` : "Loading...",
    mempoolTotalSize: mempoolInfo ? `${formatLargeNumber(mempoolInfo.usage, 1, true)}B` : 'Loading...',
    flowInExchange: data ? `${formatNumber(data.flow_in_exchange_native_units, 0)}` : 'Loading...',
    flowOutExchange: data ? `${formatNumber(data.flow_out_exchange_native_units, 0)}` : 'Loading...',
    assetAge: `${formatNumber(daysSince("2009-01-03"))}`,
    avgUtxoAge: data ? `${formatNumber(data.average_utxo_age / 365, 2)} Years` : 'Loading...',
    avgBlockTime: data ? `${formatNumber(data.block_time / 60, 1)} Mins` : 'Loading...',
    predictedNextDiff: data ? `${formatLargeNumber(data.next_difficulty_estimate, 2, false)}` : 'Loading...',
    newBlocks24h: data ? `${formatNumber(data.count_of_blocks_added)}` : 'Loading...',
    addressesOver1Btc: data ? `${formatNumber(data.addresses_balance_greater_1_native_units_count, 0)}` : 'Loading...',
    silverToBtc: data ? `${formatNumber(data.current_price_xag, 0)} oz.` : 'Loading...',
    goldToBtc: data ? `${formatNumber(data.current_price_xau, 2)} oz.` : 'Loading...',
    bigMacToBtc: bitcoinPrice ? `${formatNumber((bitcoinPrice / 5.15), 0)} 🍔` : "Loading...",
    lightningChannels: data ? `${formatNumber(data.lightning[1].channel_count, 0)}` : 'Loading...',
    lightningCapacity: data ? `${formatNumber(data.lightning_latest.latest.total_capacity / 100000000, 0)} BTC` : 'Loading...',
    transactions24h: data ? `${formatNumber(data.transfer_count_last_24_hours, 0)}` : 'Loading...',
    avgTxValue: data ? `${formatNumber((data.average_transaction_value), 3)} BTC` : 'Loading...',
    txAllTime: data ? `${formatLargeNumber(data.transaction_count_all_time, 1, false)}` : 'Loading...',
    sixOneFive: bitcoinPrice ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(6.15 * convertBitcoinPrice(bitcoinPrice), 0)}` : 'Loading...',
  };

  if (!data || !bitcoinPrice || !lastMempoolBlockData || !fees || !mempoolInfo || !conversionRates || !selectedCurrency) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }
    if (bitcoinPrice === null || bitcoinPrice === undefined) {
      console.error("Error: Bitcoin price is null or undefined");
    }
    if (lastMempoolBlockData === null || lastMempoolBlockData === undefined) {
      console.error("Error: Last mempool block data is null or undefined");
    }
    if (fees === null || fees === undefined) {
      console.error("Error: Fees data is null or undefined");
    }
    if (mempoolInfo === null || mempoolInfo === undefined) {
      console.error("Error: Mempool info data is null or undefined");
    }
    if (conversionRates === null || conversionRates === undefined) {
      console.error("Error: Conversion rates data is null or undefined");
    }
    if (selectedCurrency === null || selectedCurrency === undefined) {
      console.error("Error: Selected currency data is null or undefined");
    }
    return <CustomSpinner />;
  } else {

    return (
      <>
        <Flex
          align="center"
          flexDirection={{ base: 'column', sm: 'column', 'sm2': 'row', md: 'row' }}
          flexWrap="wrap"
          mt={{ base: '60px', md: "30px" }}
          mb="20px"
          mx={{ base: '0px', md: "20px" }}
          justifyContent={{ base: 'center', sm: 'center', 'sm2': 'center', md: 'space-between' }}
        >
          <Flex align="center"
            columnGap={{ base: 5, md: 8 }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            mr={{ base: 0, md: 10 }}
            mb={{ base: '30px', md: 0 }}
          >
            <ThemedLogoIcon icon={faBtc} fontSize="94px" rotate={12} />
            <BitcoinPrice bitcoinPrice={bitcoinPrice} selectedCurrency={selectedCurrency} onClick={() => setIsCurrencyModalOpen(true)} data={data} />

          </Flex>
          <Grid
            templateColumns={{
              base: '1fr',
              xs: 'repeat(3, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(3, 1fr)',
              xl: 'repeat(6, 1fr)',
              '2xl': 'repeat(7, 1fr)'
            }}
            templateRows={{
              base: 'repeat(6, 1fr)',
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
              xl: '1fr',
              '2xl': '1fr'
            }}
            gap={{ base: 6, xs: 6, 'sm2': 6, sm: 6, md: 6, lg: 6, xl: 6, '2xl': 6 }}
            flex="1"
            justifyItems="center"
            alignItems={{ base: 'flex-start', md: 'center' }}
            maxW={'100%'}
          >
            {selectedData.map((id, index) => {
              const hoverColor = colorMode === 'dark' ? 'white' : 'black';
              if (index === selectedData.length - 1 && (breakpoint === 'base' || breakpoint === 'xs' || breakpoint === 'sm2' || breakpoint === 'sm' || breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl')) {
                return (
                  <SingleDataValue
                    key={index}
                    id={dataOptions[id].id}
                    label={dataOptions[id].label}
                    value={valueMap[dataOptions[id].id]}
                    onClick={(id) => onOpen(id)}
                    display={{
                      base: 'none',
                      xs: 'none',
                      'sm2': 'none',
                      sm: 'none',
                      md: 'none',
                      lg: 'none',
                      xl: 'none',
                      '2xl': 'block'
                    }}
                    hoverColor={hoverColor}
                  />
                );
              }
              return (
                <SingleDataValue
                  key={index}
                  id={dataOptions[id].id}
                  label={dataOptions[id].label}
                  value={valueMap[dataOptions[id].id]}
                  onClick={(id) => onOpen(id)}
                  hoverColor={hoverColor}
                />
              );
            })}
          </Grid>
        </Flex>

        <Modal isOpen={isCurrencyModalOpen} onClose={() => setIsCurrencyModalOpen(false)}>
          <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
          <ModalContent
            bg={modalBgColor}
            color={modalTextColor}
            borderWidth="1px"
            borderColor={modalBorderColor}
            maxW={{ base: '80%', xs: '80%', 'sm2': '80%', sm: '80%', md: '64%', lg: '64%', xl: '64%', '2xl': '64%' }}
            borderRadius="6px"
            px="20px"
            pt="10px"
            pb="30px"
          >
            <ModalHeader>Select Currency</ModalHeader>
            <ModalCloseButton
              zIndex={1}
              _hover={{
                bg: 'gray.500',
              }}
              _focus={{
                boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
              }}
            />
            <ModalBody>
              <Grid
                templateColumns={{ base: 'repeat(2, 1fr)', xs: 'repeat(2, 1fr)', 'sm2': 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(5, 1fr)', lg: 'repeat(5, 1fr)', xl: 'repeat(5, 1fr)', '2xl': 'repeat(10, 1fr)' }}
                gap={{ base: 4, md: 10 }}
              >
                {Object.keys(currencySymbols).map((currency) => (
                  <CurrencyModalButton
                    key={currency}
                    mb={{ base: 8, md: 20 }}
                    onClick={() => {
                      setSelectedCurrency(currency);
                      setIsCurrencyModalOpen(false);
                    }}
                  >
                    {currencySymbols[currency]}
                  </CurrencyModalButton>
                ))}
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>


        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
          <ModalContent
            bg={modalBgColor}
            color={modalTextColor}
            borderWidth="1px"
            borderColor={modalBorderColor}
            maxW={{ base: '80%', xs: '80%', 'sm2': '80%', sm: '80%', md: '64%', lg: '64%', xl: '64%', '2xl': '64%' }}
            borderRadius="6px"
            px="20px"
            pt="10px"
            pb="30px"
          >
            <ModalHeader>Choose Your Data</ModalHeader>
            <ModalCloseButton
              zIndex={1}
              _hover={{
                bg: 'gray.500',
              }}
              _focus={{
                boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
              }}
            />
            <ModalBody>
              <Grid
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
                gap={{ base: 2, md: 2 }}
              >
                {dataOptions.map((option) => (
                  <ThemedModalButton
                    key={option.id}
                    mb={{ base: 8, md: 20 }}
                    onClick={() => handleSwap(option.id)}
                  >
                    {option.label}
                  </ThemedModalButton>
                ))}
              </Grid>
              <Text textAlign={'center'} mt={10} fontSize={'xl'} color={itsUpToYou}>It's Up To You</Text>
            </ModalBody>

          </ModalContent>
        </Modal>
      </>
    );
  };
}
