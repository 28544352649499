import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MarketTileExchangeFlowIn = ({ data, formatLargeNumber, animate }) => {
  const numberValue = data
    ? `${formatLargeNumber(data.flow_in_exchange_native_units, 2, false)} BTC`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Exch Flow In" value={numberValue} />
    </ThemedFlex>
  );
};
