// DataTable.jsx
import { DataTableHeader } from './DataTableHeader';
import { DataTableRows } from './DataTableRows';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { HighlightedDataPoint } from './HighlightedDataPoint';
import { usePagination } from '../../utils/usePagination';

export const DataTable = ({
  title,
  subtitle,
  icon,
  rows,
  link,
  linkLabel = 'Learn More',
  animateLastBlock,
  animate,
  showIcon = false,
  highlightedData,
  rowsPerPage = 15,
}) => {

  const { currentPage, setCurrentPage, pageCount } = usePagination(rows, rowsPerPage);

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      minWidth={{ base: '100%', md: '400px' }}
      className={animate ? 'gray-border-custom gray-border' : ''}
      justifyContent="flex-start"
    >
      <DataTableHeader
        title={title}
        subtitle={subtitle}
        icon={icon}
        animateLastBlock={animateLastBlock}
      />
      {highlightedData && (
        <HighlightedDataPoint
          label={highlightedData.label}
          value={highlightedData.value}
        />
      )}

<DataTableRows rows={rows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)} />

      <DataTableFooter
        link={link}
        linkLabel={linkLabel}
        footerIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
        showIcon={showIcon}
        pagination={{ currentPage, setCurrentPage, pageCount }}
      />
    </ThemedFlex>
  );
};
