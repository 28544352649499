export const daysSince = (dateString) => {
    const startDate = new Date(dateString);
    const currentDate = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((currentDate - startDate) / msPerDay);
};

export const daysLeft = (halvening_time) => {
    const targetDate = new Date(halvening_time);
    const currentDate = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((targetDate - currentDate) / msPerDay);
};

export const secondsLeft = (halvening_time) => {
    const targetDate = new Date(halvening_time);
    const currentDate = new Date();
    const msPerSecond = 1000;
    return Math.floor((targetDate - currentDate) / msPerSecond);
};


export const formatDate = (timestamp, isMilliseconds = false) => {
    const date = new Date(isMilliseconds ? timestamp : timestamp * 1000);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    return `${formattedDate} ${formattedTime}`;
};
