import { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'
import { DataTableHeader } from './DataTableHeader'
import { DataTableFooter } from './DataTableFooter'
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Select, Box } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner'

export const AvgFeesOverTimeTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  formatLargeNumber,
}) => {
  const [chartData, setChartData] = useState([]);
  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data) {
      const allData = data.bitcoin_mining.map(({ timestamp, avgFees }) => ({
        x: new Date(timestamp * 1000),
        y: avgFees,
      }));

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 182,
        '3m': 91,
        '7d': 8,
        '3d': 4,
      };

      const filteredData = allData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData(filteredData);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.length > 0) {
      const latestAvgFees = chartData[chartData.length - 1].y;
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        grid: {
          borderColor: '#555555',
          position: 'back',
        },
        series: [
          {
            name: 'Average Rewards (USD)',
            data: chartData,
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: function (value) {
              return '$' + formatLargeNumber(value, 0);
            },
            style: {
              colors: '#999',
            },
          },
        },
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: function (value) {
              return '$' + formatLargeNumber(value, 2);
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: latestAvgFees,
              borderColor: '#FFA500',
              borderWidth: 0,
              fillColor: '#FFA500',
              label: {
                borderColor: '#FFA500',
                borderWidth: 2,
                style: {
                  color: '#000000',
                  background: '#FFA500',
                  fontWeight: 'bold',
                  fontSize: '13px',
                },
                text: `$${formatLargeNumber(latestAvgFees, 2)}`,
                offsetY: 0,
                offsetX: -7,
                textAnchor: 'start',
              },
            },
          ],
        },
      };
      const chart = new ApexCharts(
        document.querySelector('#chart11'),
        options
      );
      chart.render();
      return () => {
        chart.destroy();
      };
    }
  }, [chartData, formatLargeNumber]);
  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="100px"
          mb={0}
        >
          <option value="3y">3 Years</option>
          <option value="2y">2 Years</option>
          <option value="1y">1 Year</option>
          <option value="6m">6 Months</option>
          <option value="3m">3 Months</option>
          <option value="7d">7 Days</option>
          <option value="3d">3 Day</option>
        </Select>
        <Box flexGrow={1} minHeight='360px'>
          <div id="chart11" style={{ height: '100%' }} />
        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  }
}
