import { Button, Text, useColorMode } from '@chakra-ui/react';

export const ThemedModalButton = ({ children, onClick }) => {
  const { colorMode } = useColorMode();
  const textColor = colorMode === 'dark' ? 'gray.300' : 'gray.700';
  const hoverIconColor = colorMode === 'dark' ? 'white' : 'blue.500';

  return (
    <Button
      bg="transparent"
      color={textColor}
      mr={2}
      mb={0.5}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      borderRadius="0"
      borderBottomWidth="0"
      onClick={onClick}
      maxW={'100%'}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      _hover={{
        bg: 'transparent',
        color: hoverIconColor,
      }}
    >
      <Text fontSize="14px">{children}</Text>
    </Button>
  );
};
