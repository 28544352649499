// DataTableFooter.jsx
import { Flex, Button } from '@chakra-ui/react';
import { LearnMoreButton } from '../buttons';

export const DataTableFooter = ({ link, linkLabel = 'Learn More', footerIcon, showIcon, pagination }) => {
  const { currentPage, setCurrentPage, pageCount } = pagination || {}; // Handle missing pagination

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Flex mt={2} justifyContent="space-between" alignItems="center">
      {link && (
        <LearnMoreButton link={link} linkLabel={linkLabel} icon={footerIcon} showIcon={showIcon} />
      )}
      {pageCount > 1 && ( // Show pagination if rowsPerPage exceeds default or setting
        <Flex>
          <Button size="xs" mr={2} onClick={handlePrevPage} disabled={currentPage === 1} borderRadius={3}>
            Prev
          </Button>
          <Button size="xs" onClick={handleNextPage} disabled={currentPage === pageCount} borderRadius={3}>
            Next
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
