// DataTableHeader.jsx
import { Flex, Box, Text, useColorMode } from "@chakra-ui/react";

export const DataTableHeader = ({ title, subtitle, icon, animateLastBlock }) => {
  const { colorMode } = useColorMode();
  const titleColor = colorMode === "dark" ? "white.500" : "primary.700";
  const subtitleColor = colorMode === "dark" ? "gray.700" : "gray.600";

  const animatedTitleColor =
    animateLastBlock && title === "Latest Block" ? "orange.500" : titleColor;

  return (
    <Flex justify="space-between" gap="30px" mb="7px">
      <Box>
        <Text
          fontSize="22px"
          fontWeight="700"
          color={animatedTitleColor}
          lineHeight="none"
          mb="7px"
        >
          {title}
        </Text>

        <Text
          fontSize="14px"
          fontWeight="400"
          color={subtitleColor}
          lineHeight="shorter"
        >
          {subtitle}
        </Text>
      </Box>

      <Box>{icon}</Box>
    </Flex>
  );
};
