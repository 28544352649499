import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MarketTileAllTimeHigh = ({ data, formatNumber, animate }) => {
  const numberValue = data
    ? `$${formatNumber(data.ath_usd, 0)} USD`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="All-Time High" value={numberValue} />
    </ThemedFlex>
  );
};
