import { Flex, Text, useColorMode, Link } from '@chakra-ui/react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SingleDataValue = ({ id, label, value, mb, link, display, onClick }) => {
  const { colorMode } = useColorMode();
  const labelColor = colorMode === 'dark' ? 'orange.700' : 'blue.500';
  const hoverLabelColor = colorMode === 'dark' ? 'orange.500' : 'blue.400';
  const valueColor = colorMode === 'dark' ? 'white' : 'black';
  const hoverValueColor = colorMode === 'dark' ? 'white' : 'black';
  const linkedFontSize = link ? '16px' : '20px';
  const LOADING_TEXT = 'Loading...'

  return (
    <Flex
      id={id}
      direction="column"
      gap="7px"
      minW={{ base: '100px', md: 0 }}
      textAlign={{ base: "center", md: "center" }}
      display={display}
      maxW={{ base: '100%', md: 'unset' }}
      cursor="pointer"
      onClick={() => onClick(id)}
      _hover={{
        '.label': { color: hoverLabelColor },
        '.value': { color: hoverValueColor },
      }}
    >
      <Text
        className="label"
        fontSize={{ base: "11px", md: "12px" }}
        color={labelColor}
        fontWeight="700"
        lineHeight="none"
        textTransform="uppercase"
        align={{ base: 'center', md: 'unset' }}
      >
        {label}
      </Text>

      <Flex align="center" justify={{ base: "center", md: "center" }}>
        {link ? (
          <Link href={link} isExternal>
            <Flex align="center">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
              <Flex ml={2} alignItems="center">
                <Text
                  className="value"
                  fontSize={linkedFontSize}
                  fontWeight="700"
                  lineHeight={{ base: "1.2em", md: "none" }}
                  align={{ base: 'center', md: 'unset' }}
                  mb={mb}
                  mr={2}
                  color={valueColor}
                >
                  {value ?? LOADING_TEXT}
                </Text>
              </Flex>
            </Flex>
          </Link>
        ) : (
          <Text
            className="value"
            fontSize={{ base: "18px", md: "20px" }}
            fontWeight="700"
            lineHeight="none"
            align={{ base: 'center', md: 'unset' }}
            mb={mb}
            color={valueColor}
          >
            {value ?? LOADING_TEXT}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
