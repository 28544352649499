import { Flex, Progress, Text, Box, useColorMode } from '@chakra-ui/react';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';

const DIFFICULTY_ADJUSTMENT_INTERVAL = 2016;

export const DifficultyAdjustmentTile = ({
    title,
    icon,
    subtitle,
    link,
    linkLabel,
    lastMempoolBlockData,
    da,
}) => {
    const currentHeight = lastMempoolBlockData ? lastMempoolBlockData.height : 0;
    const diffChangePercentage = da ? `${da.difficultyChange.toFixed(3)}%` : "Loading...";
    const adjustmentCount = Math.floor(currentHeight / DIFFICULTY_ADJUSTMENT_INTERVAL);
    const startHeight = adjustmentCount * DIFFICULTY_ADJUSTMENT_INTERVAL - 1;
    const endHeight = (adjustmentCount + 1) * DIFFICULTY_ADJUSTMENT_INTERVAL - 1;
    const progress = ((currentHeight - startHeight) / (endHeight - startHeight)) * 100;
    const indicatorPosition = progress + '%';

    const { colorMode } = useColorMode();

    const gridColor = colorMode === 'dark' ? '#121212' : '#CCCCCC';
    const bgColor = colorMode === 'dark' ? 'orange.500' : '#0d579b';
    const schemeColor = colorMode === 'dark' ? 'orange' : 'blue';
    const indicatorColor = colorMode === 'dark' ? '#121212' : '#ffffff';



    return (
        <ThemedFlex
            direction="column"
            mb="12px"
            py="18px"
            px="20px"
            flex={1}
            borderRadius={3}
        >
            <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
            <Progress
                value={progress}
                colorScheme={schemeColor}
                bg={gridColor}
                size="xs"
                mb="2"
                mt="2"
            />

            <Box position="relative">
                <Box position="absolute" left={indicatorPosition} top="-48px" transform="translateX(-50%)">
                    <Box bgColor={bgColor} color={indicatorColor} py="2px" px="8px" borderRadius="md" fontWeight="bold">
                        <Text fontSize="14px">{Math.floor(progress)}%</Text>
                    </Box>
                    <Box bgColor={bgColor} w="10px" h="10px" transform="rotate(45deg)" position="absolute" left="40%" bottom="-5px" />

                </Box>
            </Box>

            <Flex justifyContent="space-between" mt="2">
                <Text fontSize={{ base: "12px", md: "14px" }} fontWeight={'bold'}>{(endHeight - currentHeight + 1).toLocaleString()} blocks left</Text>
                <Text fontSize={{ base: "9px", md: "11px" }} color="#999" display={{ xs: "none", sm: "inline" }}>
                    {` (est. ${new Date(
                        // adjust the date by removing or adding +/- 86400000 if needed
                        (endHeight - currentHeight) * 10 * 60 * 1000 + Date.now()
                    ).toLocaleDateString()
                        })`}
                </Text>
                <Text fontSize={{ base: "12px", md: "14px" }} fontWeight={'bold'}>
                    Est change {diffChangePercentage}</Text>
            </Flex>
            <DataTableFooter link={link} linkLabel={linkLabel} />
        </ThemedFlex>
    )
}
