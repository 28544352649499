import { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Box } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const PoolsTopTenTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  formatNumber
}) => {
  const [chartData, setChartData] = useState([]);

  const chartRef = useRef(null);

  useEffect(() => {
    if (data) {
      const sortedData = data.mining_pools.pools.sort((a, b) => b.blockCount - a.blockCount);
      const topTenPools = sortedData.slice(0, 10);
      const processedData = topTenPools.map(pool => ({
        y: pool.blockCount,
        x: pool.name,
      }));
      setChartData(processedData);
    }
  }, [data]);

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'bar',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        grid: {
          borderColor: '#555555',
          position: 'back',
        },
        series: [
          {
            name: 'Block Count',
            data: chartData,
          },
        ],
        xaxis: {
          categories: chartData.map(data => data.x),
          labels: {
            style: {
              colors: '#999',
            },
            formatter: (value) => {
              return formatNumber(value, 0);
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'Mining Pools',
              style: {
                color: '#999',
              },
            },
            labels: {
              style: {
                colors: '#999',
              },
            },
          },
        ],
        colors: ['#ffa500'],
        stroke: {
          width: 2,
        },
        dataLabels: {
          dropShadow: {
            enabled: false
          },
          style: {
            colors: ['#121212'],
          },
          formatter: (value) => {
            return `${formatNumber(value)}`;
          },
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: (value) => {
              return formatNumber(value);
            },
          },
        },
      };
      const chart = new ApexCharts(
        chartRef.current,
        options
      );

      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, formatNumber]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Box flexGrow={1} minHeight='337px'>
          <Box ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  };
};
