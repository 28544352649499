// src/utils/useTimechainWebSocket.js

import { useState, useEffect, useRef, useCallback } from "react";

// At the top of your useTimechainWebSocket.js file
const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0] && args[0].indexOf && args[0].indexOf('WebSocket connection to \'wss://timechaindata.com/\' failed') !== -1) {
    return;
  }
  originalConsoleError.apply(console, args);
};

export const useTimechainWebSocket = () => {
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (wsRef.current?.readyState === WebSocket.OPEN) return;

    wsRef.current = new WebSocket("wss://timechaindata.com");

    wsRef.current.onopen = () => {
      console.log("WebSocket connected");
      setIsConnected(true);
    };

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.weightedPrice) {
        setBitcoinPrice(parseFloat(data.weightedPrice).toFixed(2));
      }
    };

    wsRef.current.onerror = (error) => {
      // console.error("TimechainData WebSocket error:", error);
    };

    wsRef.current.onclose = () => {
      // console.log("TimechainData WebSocket closed");
      setIsConnected(false);
      reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
    };
  }, []);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (wsRef.current) wsRef.current.close();
      if (reconnectTimeoutRef.current) clearTimeout(reconnectTimeoutRef.current);
    };
  }, [connectWebSocket]);

  return { bitcoinPrice, isConnected };
};