import { Flex, Progress, Text, Box, useColorMode } from '@chakra-ui/react';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';

const HALVING_INTERVAL = 210000;

export const ProgressTile = ({
  title,
  icon,
  subtitle,
  link,
  linkLabel,
  lastMempoolBlockData,
}) => {
  const currentHeight = lastMempoolBlockData ? lastMempoolBlockData.height : 0;
  const postReward = 1.5625

  const halvingCount = Math.floor(currentHeight / HALVING_INTERVAL);
  const startHeight = halvingCount * HALVING_INTERVAL;
  const endHeight = (halvingCount + 1) * HALVING_INTERVAL;
  const progress = ((currentHeight - startHeight) / (endHeight - startHeight)) * 100;
  const indicatorPosition = progress + '%';

  const { colorMode } = useColorMode();

  const gridColor = colorMode === 'dark' ? '#121212' : '#CCCCCC';
  const bgColor = colorMode === 'dark' ? 'orange.500' : '#0d579b';
  const schemeColor = colorMode === 'dark' ? 'orange' : 'blue';
  const indicatorColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Progress
        value={progress}
        colorScheme={schemeColor}
        bg={gridColor}
        size="xs"
        mb="2"
        mt="2"
      />
      <Box position="relative">
        <Box position="absolute" left={indicatorPosition} top="-48px" transform="translateX(-50%)">
          <Box bgColor={bgColor} color={indicatorColor} py="2px" px="8px" borderRadius="md" fontWeight="bold">
            <Text fontSize="14px">{Math.floor(progress)}%</Text>
          </Box>
          <Box bgColor={bgColor} w="10px" h="10px" transform="rotate(45deg)" position="absolute" left="40%" bottom="-5px" />
        </Box>
      </Box>

      <Flex justifyContent="space-between" mt="2">
        <Text fontSize={{ base: "12px", md: "14px" }} fontWeight={'bold'}>{(endHeight - currentHeight).toLocaleString()} blocks left</Text>
        <Text fontSize={{ base: "9px", md: "11px" }} color="#999" display={{ xs: "none", sm: "inline" }}>
          {` (est. ${new Date((endHeight - currentHeight) * 10 * 60 * 1000 + Date.now()).toLocaleDateString() // adjust the date by removing or adding +/- 86400000 if needed
            })`}
        </Text>
        <Text fontSize={{ base: "12px", md: "14px" }} fontWeight={'bold'}>New subsidy {postReward} BTC</Text>
      </Flex>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};