import React from 'react';
import { Flex, Text, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from '../../data-display/CustomSpinner';
import { createCurrencyConverter, currencySymbols } from '../../../utils';

export const BitcoinPrice = ({ bitcoinPrice, data, selectedCurrency, onClick }) => {
  const { colorMode } = useColorMode();
  const textColor = colorMode === 'dark' ? 'orange.500' : '#0d579b';

  const conversionRates = createCurrencyConverter(data);
  const conversionRate = conversionRates[selectedCurrency];

  if (!conversionRate || isNaN(conversionRate)) {
    return <CustomSpinner />;
  }

  const convertedPrice = bitcoinPrice * conversionRate;

  let formattedPrice = null;

  if (convertedPrice) {
    if (
      selectedCurrency.toLowerCase() === 'ars' ||
      selectedCurrency.toLowerCase() === 'jpy' ||
      selectedCurrency.toLowerCase() === 'krw' ||
      selectedCurrency.toLowerCase() === 'inr' ||
      selectedCurrency.toLowerCase() === 'php'
    ) {
      formattedPrice = parseFloat(convertedPrice).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      formattedPrice = parseFloat(convertedPrice).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    if (selectedCurrency.toLowerCase() === 'usd') {
      formattedPrice = '$' + formattedPrice;
    }
  }

  if (!bitcoinPrice || !data || !selectedCurrency) {
    return <CustomSpinner />;
  }

  return (
    <Flex mb={{ base: '4px', md: '4px' }} textAlign="center" onClick={onClick || undefined} cursor="pointer">
      <div>
        {formattedPrice ? (
          <>
            <Text
              fontSize={{ base: "16px", md: "16px" }}
              color={textColor}
              fontWeight="700"
              lineHeight="none"
              textTransform="uppercase"
              align={{ base: 'center', md: 'unset' }}
            >
              BITCOIN NOW ({selectedCurrency.toUpperCase()})
            </Text>
            <Flex
              fontSize={{ base: "40px", md: "34px" }}
              fontWeight="700"
              lineHeight="none"
              align="center"
              mt={{ base: 2, md: 1 }}
            >
              <Text as="span" fontSize={{ base: "26px", md: "26px" }} mr={2}>
                {currencySymbols[selectedCurrency] || ''}
              </Text>
              <Text>{formattedPrice}</Text>
            </Flex>
          </>
        ) : (
          <CustomSpinner />
        )}
      </div>
    </Flex>
  );
};
