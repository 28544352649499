import { useState } from 'react';
import { Flex, useColorMode, useBreakpointValue } from '@chakra-ui/react';
import { ThemedNavbarButton } from '../../../theme/ThemedNavbarButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxArchive, faChartBar, faDashboard, faDigging,
  faUsers,
  faMedal, faNetworkWired, faPieChart,
} from '@fortawesome/free-solid-svg-icons';
import { SupportersModal } from '../../data-display/SupportersModal';
import { DonationsModal } from '../../data-display/DonationsModal';
import { ThemedDonateButton } from '../../../theme/ThemedDonateButton';
import { ThemedModalButton } from '../../../theme/ThemedModalButton';
// import { ThemedText } from '../../../theme/ThemedText';
import { faBtc } from '@fortawesome/free-brands-svg-icons';

export const Navbar = ({ filter, setFilter }) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? 'primary.700' : 'secondary.500';
  const isSmallish2Screen = useBreakpointValue({ base: true, xl: false });
  const isSmallishScreen = useBreakpointValue({ base: true, lg: false });
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [isDonationsModalOpen, setDonationsModalOpen] = useState(false);
  const [isSupportersModalOpen, setSupportersModalOpen] = useState(false);
  const isDonateButtonDisabled = false;

  const buttonsConfig = [
    { category: 'All', icon: faDashboard, text: 'DASHBOARD', showOnSmallishScreen: true },
    { category: 'Network', icon: faNetworkWired, text: 'NETWORK', showOnSmallishScreen: true },
    { category: 'Mining', icon: faDigging, text: 'MINING', showOnSmallishScreen: true },
    { category: 'Market', icon: faPieChart, text: 'MARKET', showOnSmallishScreen: true },
    { category: 'Charts', icon: faChartBar, text: 'CHARTS', showOnSmallishScreen: true },
    { category: 'Resources', icon: faBoxArchive, text: 'RESOURCES', showOnSmallishScreen: true },
    { category: 'Tv', icon: faMedal, text: 'STOCKS vs BTC', showOnSmallishScreen: false, showOnSmallScreen: false }, // fix this, don't hide it
  ];

  const handleClick = (category) => setFilter(category);
  const openSupportersModal = () => setSupportersModalOpen(true);
  const closeSupportersModal = () => setSupportersModalOpen(false);
  const openDonationsModal = () => setDonationsModalOpen(true);
  const closeDonationsModal = () => setDonationsModalOpen(false);
  const becomeSupporter = () => {
    closeSupportersModal();
    openDonationsModal();
  };
  const donateForm = () => {
    closeDonationsModal();
    openSupportersModal();
  };

  const renderButtonText = (text) => !(isSmallScreen || isSmallishScreen) && <>{text}</>;

  return (
    <Flex
      mx={0}
      borderBottomWidth="0"
      borderBottomColor="gray.600"
      bgColor={bgColor}
      paddingTop={{ base: 0, md: 2 }}
      paddingBottom={{ base: 0, md: 2 }}
      mb={4}
      justifyContent={{ base: "space-between", md: "space-between" }}
      alignItems="center"
      flexWrap="nowrap"
    >
      <Flex flexWrap="nowrap" alignItems="center" flexGrow={{ base: 0, md: 0 }}>
        {buttonsConfig.map((button, index) => (
          (!isSmallScreen || button.showOnSmallScreen || button.showOnSmallishScreen) && (
            <ThemedNavbarButton
              key={index}
              filter={filter}
              category={button.category}
              onClick={() => handleClick(button.category)}
              flexGrow={{ base: 1, md: 0 }}
            >
              <FontAwesomeIcon icon={button.icon} style={{ marginRight: isSmallishScreen ? '0' : '0.5rem' }} size={'lg'} />
              {renderButtonText(button.text)}
            </ThemedNavbarButton>
          )
        ))}
      </Flex>

      <Flex alignItems="center">
        {/* {!isSmallishScreen && !isSmallish2Screen && <ThemedText>
          Help Support TimechainStats
        </ThemedText>} */}
        {!isSmallScreen && (
          <ThemedModalButton onClick={openSupportersModal} _hover={{ borderBottom: 'none' }} borderBottomColor={'none'}>
            <FontAwesomeIcon icon={faUsers} size={'lg'} />
          </ThemedModalButton>
        )}
        {!isDonateButtonDisabled && (
          <ThemedDonateButton onClick={openDonationsModal}>
            <FontAwesomeIcon icon={faBtc} style={{ marginRight: (isSmallishScreen || isSmallish2Screen) ? '0' : '0.5rem' }} size={'lg'} />
            {!isSmallishScreen && !isSmallish2Screen && 'DONATE'}
          </ThemedDonateButton>
        )}
      </Flex>

      <DonationsModal isOpen={isDonationsModalOpen} onClose={closeDonationsModal} donateForm={donateForm} />
      <SupportersModal isOpen={isSupportersModalOpen} onClose={closeSupportersModal} onBecomeSupporter={becomeSupporter} />
    </Flex>
  );
}
