// AppContent.jsx
import { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box,
    Text,
    Flex,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBasketShopping, faBitcoinSign, faBook, faCalendarAlt, faChartLine, faCircleQuestion, faDatabase, faDollar, faEye, faGem, faGlobeAsia, faKey, faLock, faPercent, faPieChart, faQuestionCircle, faRightLeft, faScissors, faSliders, faUpRightFromSquare, faUserSecret, faWrench, faTimeline, faPlay, faPenFancy, faCoins, faMoneyBill, faHistory, faHandFist, faCode, faCodePullRequest, faMoneyBills, faLegal, faDiceD20, faFireAlt, faGraduationCap, faSmile, faComputer, faMicrochip, faBarChart, faCube, faExternalLinkAlt, faChartPie, faGears, faMedal, faBoltLightning
} from '@fortawesome/free-solid-svg-icons'
import {
    AvgFeesOverTimeTile, ChartTile, DataTable, DifficultyAdjustmentTile, ImageTile, MarketCapTile, ProgressTile, QuoteTile, WordTile, VolumeTile, HashRateTile, PoolsTopTenTile, PoolsTopTenPieTile, RewardsToFeesTile, RewardsToFeesBarTile, AvgRewardsOverTimeTile, LightningNodesTile, LightningNodesByCountryTile, LightningCapacityByCountryTile, PoolsTopTenHashPieTile, TransactionsTile, TransactionsAndHashRateTile, HashRatePriceTile, HashRateMempoolSizeTile, DataTileTargetBlockTime, DataTileRealBlockTime, DataTileCirculatingSupply, DataTileMaximumSupply, DataTilePercentMined, DataTileLeftToMine
} from '../../data-display'
import { DataBanner } from '../../features/data-banner'
import { ContentWrapper } from './ContentWrapper'
import { Navbar } from '../header/Navbar'
import { faBtc } from '@fortawesome/free-brands-svg-icons';
import { ResourceTileWhitepaper } from '../../data-display/ResourceTileWhitepaper';
import { ResourceTileShellingOut } from '../../data-display/ResourceTileShellingOut';
import { ResourceTileDevGuides } from '../../data-display/ResourceTileDevGuides';
import { ResourceTileCypherpunkManifesto } from '../../data-display/ResourceTileCypherpunkManifesto';
import { ResourceTileBips } from '../../data-display/ResourceTileBips';
import { ResourceTileWiki } from '../../data-display/ResourceTileWiki';
import { MarketTileExchangeFlowIn } from '../../data-display/MarketTileExchangeFlowIn';
import { MarketTileExchangeFlowOut } from '../../data-display/MarketTileExchangeFlowOut';
import { MarketTileAllTimeHigh } from '../../data-display/MarketTileAllTimeHigh';
import { MarketTileAllTimeLow } from '../../data-display/MarketTileAllTimeLow';
import { MarketTileAthPercentage } from '../../data-display/MarketTileAthPercentage';
import { MarketTileBtcToGold } from '../../data-display/MarketTileBtcToGold';
import { MiningTileHashRate } from '../../data-display/MiningTileHashRate';
import { MiningTileDifficulty } from '../../data-display/MiningTileDifficulty';
import { MiningTileRewardBtc } from '../../data-display/MiningTileRewardBtc';
import { MiningTileRewardFees } from '../../data-display/MiningTileRewardUsd';
import { MiningTileMempoolTxs } from '../../data-display/MiningTileMempoolTxs';
import { MiningTileMempoolSize } from '../../data-display/MiningTileMempoolSize';
import { NetworkTileTimechainReachable } from '../../data-display/NetworkTileTimechainReachable';
import { NetworkTileAge } from '../../data-display/NetworkTileAge';
import { NetworkTileAvgUtxoAge } from '../../data-display/NetworkTileAvgUtxoAge';
import { NetworkTileBlocks24h } from '../../data-display/NetworkTileBlocks24h';
import { NetworkTileBtcPerCountry } from '../../data-display/NetworkTileBtcPerCountry';
import { BitcoinDivider } from '../../decorations/BitcoinDivider';
import { ChartTileTransactions24h } from '../../data-display/ChartTileTransactions24h';
import { CustomSpinner } from '../../data-display/CustomSpinner';
import { RecentBlocks } from '../../data-display/RecentBlocks';
import {
    formatNumber,
    formatLargeNumber,
    truncateMiddle,
    daysSince,
    daysLeft,
    secondsLeft,
    formatDate,
} from '../../../utils';
import { TradingViewChart2 } from '../../data-display/TradingViewChart2';
import { createCurrencyConverter, currencySymbols } from '../../../utils';
import { useRecoilValue } from 'recoil';
import { selectedCurrencyState } from '../../../recoilState';

// import { TcsAlert } from './TcsAlert';

const flagAR = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇦🇷</span>; // Argentina
const flagAU = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇦🇺</span>; // Australia
const flagBR = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇧🇷</span>; // Brazil
const flagCA = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇦</span>; // Canada
const flagCH = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇭</span>; // Switzerland
const flagCZ = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇿</span>; // Czech Republic
const flagDK = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇩🇰</span>; // Denmark
const flagEU = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇪🇺</span>; // European Union
const flagIN = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇮🇳</span>; // India
const flagJP = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇯🇵</span>; // Japan
const flagKR = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇰🇷</span>; // South Korea
const flagMX = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇲🇽</span>; // Mexico
const flagNO = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇳🇴</span>; // Norway
const flagNZ = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇳🇿</span>; // New Zealand
const flagPH = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇵🇭</span>; // Philippines
const flagPL = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇵🇱</span>; // Poland
const flagSE = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇸🇪</span>; // Sweden
const flagSG = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇸🇬</span>; // Singapore
const flagUS = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇺🇸</span>; // USA

export const AppContent = ({ data, bitcoinPrice, lastMempoolBlockData, fees, mempoolInfo, da, blocks }) => {
    const selectedCurrency = useRecoilValue(selectedCurrencyState);
    const [conversionRates, setConversionRates] = useState(null);
    useEffect(() => {
        if (data) {
            const rates = createCurrencyConverter(data);
            setConversionRates(rates);
        }
    }, [data, bitcoinPrice]);

    const startPopulation = 8030868232;
    const dailyNetIncrease = 215000;
    const netIncreasePerMinute = dailyNetIncrease / (60 * 24);
    const startDate = new Date("2023-05-02");

    const calculateCurrentPopulation = () => {
        const today = new Date();
        const minutesElapsed = Math.floor((today - startDate) / (1000 * 60));
        const currentPopulation = startPopulation + (minutesElapsed * netIncreasePerMinute);
        return currentPopulation;
    };

    const [filter, setFilter] = useState("All");
    const [animateLastBlock, setAnimateLastBlock] = useState(false);
    const [dataTableTitle, setDataTableTitle] = useState(
        lastMempoolBlockData ? "Latest Block" : "Last Block"
    );

    const prevBlockHeightRef = useRef(null);
    const isFirstRender = useRef(true);


    const animateBorders = useCallback(() => {

        setAnimateLastBlock(true);

        setDataTableTitle(`Block ${formatNumber(lastMempoolBlockData.height, 0)} Found!`);


        setTimeout(() => {

            setAnimateLastBlock(false);

            setDataTableTitle("Last Block");
        }, 20000);
    }, [lastMempoolBlockData]);

    useEffect(() => {
        if (lastMempoolBlockData && (!prevBlockHeightRef.current || prevBlockHeightRef.current < lastMempoolBlockData.height)) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                prevBlockHeightRef.current = lastMempoolBlockData.height;
            } else {
                animateBorders();
                prevBlockHeightRef.current = lastMempoolBlockData.height;
            }
        }
    }, [lastMempoolBlockData, animateBorders]);

    const currencies1Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagAR}<span> Argentine Peso</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['ars']} ${formatNumber(bitcoinPrice * conversionRates.ars, 0)}` : 'Loading...', // Add 1.96 - Check https://coinmonitor.info/ - Street rate is different than official rate
                tooltipText: "The price of Bitcoin in Argentine Peso",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagAU}<span> Australian Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['aud']} ${formatNumber(bitcoinPrice * conversionRates.aud, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Australian Dollar",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagBR} <span>Brazilian Real</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['brl']} ${formatNumber(bitcoinPrice * conversionRates.brl, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Brazilian Real",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagCA} <span>Canadian Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['cad']} ${formatNumber(bitcoinPrice * conversionRates.cad, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Canadian Dollar",
            },
        ]
        : [];

    const currencies2Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagCZ} <span>Czech Koruna</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['czk']} ${formatNumber(bitcoinPrice * conversionRates.czk, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Czech Koruna",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagDK} <span>Danish Krone</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['dkk']} ${formatNumber(bitcoinPrice * conversionRates.dkk, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Danish Krone",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagEU} <span>Euro</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['eur']} ${formatNumber(bitcoinPrice * conversionRates.eur, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Euro",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagIN} <span>Indian Rupee</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['inr']} ${formatNumber(bitcoinPrice * conversionRates.inr, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Indian Rupee",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagJP} <span>Japanese Yen</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['jpy']} ${formatNumber(bitcoinPrice * conversionRates.jpy, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Japanese Yen",
            },
        ]
        : [];

    const currencies3Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagKR} <span>Korean Won</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['krw']} ${formatNumber(bitcoinPrice * conversionRates.krw, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Korean Won",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagMX} <span>Mexican Peso</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['mxn']} ${formatNumber(bitcoinPrice * conversionRates.mxn, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Mexican Peso",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagNZ} <span>New Zealand Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['nzd']} ${formatNumber(bitcoinPrice * conversionRates.nzd, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in New Zealand Dollar",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagNO} <span>Norwegian Krone</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['nok']} ${formatNumber(bitcoinPrice * conversionRates.nok, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Norwegian Krone",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagPH} <span>Philippine Peso</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['php']} ${formatNumber(bitcoinPrice * conversionRates.php, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Philippine Peso",
            },
        ]
        : [];

    const currencies4Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagPL} <span>Polish Złoty</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['pln']} ${formatNumber(bitcoinPrice * conversionRates.pln, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Polish Złoty",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagSG} <span>Singapore Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['sgd']} ${formatNumber(bitcoinPrice * conversionRates.sgd, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Singapore Dollar",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagSE} <span>Swedish Krona</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['sek']} ${formatNumber(bitcoinPrice * conversionRates.sek, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Swedish Krona",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagCH} <span>Swiss Franc</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['chf']} ${formatNumber(bitcoinPrice * conversionRates.chf, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Swiss Franc",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagUS} <span>US Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['usd']} ${formatNumber(bitcoinPrice, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in US Dollar",
            }
        ]
        : [];

    const lastBlockTableData = data && bitcoinPrice && conversionRates && selectedCurrency && lastMempoolBlockData
        ? [
            {
                label: lastMempoolBlockData ? 'Block Height' : 'Loading...',
                value: lastMempoolBlockData
                  ? `Block ${formatNumber(lastMempoolBlockData.height, 0)}`
                  : 'Loading...',
                tooltipText: "The latest block added to the Bitcoin blockchain, which contains a collection of confirmed transactions.",
            },
            {
                label: lastMempoolBlockData ? 'Timestamp' : 'Loading...',
                value: lastMempoolBlockData
                  ? formatDate(lastMempoolBlockData.timestamp)
                  : 'Loading...',
                tooltipText: "The date and time when the latest block was mined and added to the blockchain.",
              },
              {
                label: lastMempoolBlockData ? 'Empty Block?' : 'Loading...',
                value: lastMempoolBlockData
                  ? (lastMempoolBlockData.tx_count === 1 ? 'true' : 'false')
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `Was the block empty?` : "Loading...",
              },
              
            {
                label: lastMempoolBlockData ? 'Mining Pool' : 'Loading...',
                value: lastMempoolBlockData
                  ? lastMempoolBlockData.extras.pool.name
                  : 'Loading...',
                tooltipText: "The mining pool that successfully mined the latest block and received the block reward.",
              },
              {
                label: lastMempoolBlockData ? 'Block Reward' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatLargeNumber(lastMempoolBlockData.extras.reward, 1, false)} Sats (${formatNumber(lastMempoolBlockData.extras.reward / 100000000, 2)} BTC)`
                  : 'Loading...',
                tooltipText: "The total reward, in satoshis, mined in the latest block, including the subsidy and all fees.",
              },
              {
                label: lastMempoolBlockData ? 'Total Subsidy' : 'Loading...',
                value: lastMempoolBlockData
                  ? `312,500,000 Sats`
                  : 'Loading...',
                tooltipText: "The total subsidy, in satoshis, earned in the latest block.",
              },
              {
                label: lastMempoolBlockData ? 'Total Fees' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatNumber(lastMempoolBlockData.extras.totalFees, 0)} Sats`
                  : 'Loading...',
                tooltipText: "The total fees, in satoshis, paid in the latest block.",
              },
              
              {
                label: lastMempoolBlockData ? 'Avg Fee Rate' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatNumber(lastMempoolBlockData.extras.avgFeeRate, 0)} Sats/vByte`
                  : 'Loading...',
                tooltipText: "The average fee rate, in satoshis per virtual byte (vByte), for transactions included in the latest block.",
              },
              {
                label: lastMempoolBlockData ? 'Highest Fee Rate' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatNumber(lastMempoolBlockData.extras.feeRange[6], 0)} Sats/vByte`
                  : 'Loading...',
                tooltipText: "The fee range, in satoshis/vByte, for transactions included in the latest block (lowest - highest).",
              },
              {
                label: lastMempoolBlockData ? 'Lowest Fee Rate' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatNumber(lastMempoolBlockData.extras.feeRange[0], 0)} Sats/vByte`
                  : 'Loading...',
                tooltipText: "The fee range, in satoshis/vByte, for transactions included in the latest block (lowest - highest).",
              },
              {
                label: lastMempoolBlockData ? 'Avg Fee' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatNumber(lastMempoolBlockData.extras.avgFee, 0)} Sats`
                  : 'Loading...',
                tooltipText: "The average fee, in satoshis, for transactions included in the latest block.",
              },
              {
                label: lastMempoolBlockData ? 'Transaction Count' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatNumber(lastMempoolBlockData.tx_count, 0)} TXs`
                  : 'Loading...',
                tooltipText: "The total number of transactions included in the latest block.",
              },
              {
                label: lastMempoolBlockData ? 'Nonce' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${lastMempoolBlockData.nonce}`
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `What was the nonce of the block?` : "Loading...",
              },
              {
                label: lastMempoolBlockData ? 'Bits' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${lastMempoolBlockData.bits}`
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `How large was the last block in bits?` : "Loading...",
              },
              {
                label: lastMempoolBlockData ? 'Difficulty' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${formatLargeNumber(lastMempoolBlockData.difficulty, 2, true)}`
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `How large was the last block in weight?` : "Loading...",
              },
              {
                label: lastMempoolBlockData ? 'Version' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${lastMempoolBlockData.version}`
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `What version was the block?` : "Loading...",
              },
              
              {
                label: lastMempoolBlockData ? 'Hash' : 'Loading...',
                value: lastMempoolBlockData
                  ? truncateMiddle(lastMempoolBlockData.id, 8)
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `The hash is a unique identifier generated by applying a cryptographic hash function to the contents of the block. This hash serves as a fingerprint for the block, ensuring its integrity and authenticity within the blockchain. If any data within the block were to be altered, the hash would change, making it easy to detect any tampering. The hash for this block is ${lastMempoolBlockData.id}` : "Loading...",
              },
              {
                label: lastMempoolBlockData ? 'Stale Block?' : 'Loading...',
                value: lastMempoolBlockData
                  ? `${lastMempoolBlockData.stale}`
                  : 'Loading...',
                tooltipText: lastMempoolBlockData ? `Was the block stale?` : "Loading...",
              },
              
        ]
        : [];

    const whatIsASatWorthData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: '1 Satoshi',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00000001, 6)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one Satoshi buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '10 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00000010, 5)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can ten Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '100 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00000100, 3)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one hundred Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '1,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00001000, 3)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one thousand Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '10,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00010000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can ten thousand Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '100,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00100000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one hundred thousand Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '1,000,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.01000000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one million Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '10,000,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.10000000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can ten million Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
            {
                label: '100,000,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 1.0000000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one hundred million Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
            },
        ]
        : [];

    const whatIsAUsdWorthData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: data && bitcoinPrice ? `1 ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((1 / (bitcoinPrice * conversionRates[selectedCurrency])) * 100000000, 0)} Sats` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 1.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `10 ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((10 / (bitcoinPrice * conversionRates[selectedCurrency])) * 100000000, 0)} Sats` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 10.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `100 ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((100 / (bitcoinPrice * conversionRates[selectedCurrency])) * 100000000, 0)} Sats` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 100.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `1k ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((1000 / (bitcoinPrice * conversionRates[selectedCurrency])) * 100000000, 0)} Sats` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 1,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `10k ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((10000 / (bitcoinPrice * conversionRates[selectedCurrency])) * 100000000, 0)} Sats` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 10,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `100k ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((100000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 100,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `1M ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((1000000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 1,000,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `10M ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((10000000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 10,000,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `100M ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((100000000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 100,000,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
            },
        ]
        : [];

    const bitcoinBreakdownData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: '21M BTC ÷ Fortune 500',
                value: `~${formatNumber(21000000 / 500, 0)} BTC/Company`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Companies in the Fortune 500 (500), assuming the Fortune 500 are the only bitcoin holders for this exercise",
            },
            {
                label: '21M BTC ÷ NYSE',
                value: `~${formatNumber(21000000 / 2385, 0)} BTC/Company`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Companies traded on the New York Stock Exchange (2,385 in 2023), assuming the NYSE companies are the only bitcoin holders for this exercise",
            },
            {
                label: '21M BTC ÷ NYC',
                value: `~${formatNumber(21000000 / 8468000, 4)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of New York (8.468M in 2023), assuming the City of New York are the only bitcoin holders for this exercise",
            },
            {
                label: '21M BTC ÷ USA',
                value: `~${formatNumber(21000000 / 331900000, 4)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of USA (331.9M in 2023), assuming the USA population are the only bitcoin holders for this exercise",
            },
            {
                label: '21M BTC ÷ Europe',
                value: `~${formatNumber(21000000 / 746400000, 4)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of Europe (746.4M in 2023), assuming the European population are the only bitcoin holders for this exercise",
            },
            {
                label: '21M BTC ÷ Japan',
                value: `~${formatNumber(21000000 / 125700000, 4)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of Japan (125.7M in 2023), assuming the Japanese population are the only bitcoin holders for this exercise",
            },
            {
                label: '21M BTC ÷ Country',
                value: `~${formatNumber(21000000 / 195, 0)} BTC/Country`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Countries (195)",
            },
            {
                label: '21M BTC ÷ Continent',
                value: `~${formatLargeNumber(21000000 / 7, 0, false)} BTC/Continent`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Continents (7)",
            },
            {
                label: '21M BTC ÷ Solar System',
                value: `~${formatLargeNumber(21000000 / 8, 0, false)} BTC/Planet`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Planets in our Solar System (8)",
            }
        ]
        : [];

    const bitcoinNetworkData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Timechain Explorer',
                value: 'https://mempool.space/',
                displayValue: 'Mempool.space',
                type: 'link',
                tooltipText: "To provide some basic terms, a block explorer is a timechain search engine that allows you to search for a particular piece of information on the timechain. The activities carried out on bitcoin's timechain are known as transactions, which occur when sats are sent to and from wallet addresses.",
            },
            {
                label: 'New Blocks (24h)',
                value: data ? `${formatNumber(data.count_of_blocks_added)} Blocks` : "Loading...",
                tooltipText: "The count of new blocks added to the timechain in the last 24 hours.",
                // highlighted: true,
            },
            {
                label: 'Timechain Reachable?',
                value: 'TRUE',
                tooltipText: "Checks the Bitcoin timechain uptime. Timechain is a distributed database existing on multiple computers at the same time. It is constantly growing as new sets of recordings, or 'blocks', are added to it. Each block contains a timestamp and a link to the previous block, so they actually form a chain. Satoshi's whitepaper never mentioned 'blockchain,' however. Satoshi called it 'Timechain.'",
            },
            {
                label: 'Miners Active?',
                value: 'TRUE',
                tooltipText: "Bitcoin mining is the process of creating new bitcoin by solving a computational puzzle. Bitcoin mining is necessary to maintain the ledger of transactions upon which bitcoin is based. Miners have become very sophisticated over the last several years using complex machinery to speed up mining operations.",
            },
            {
                label: 'Network Age',
                value: `${formatNumber(daysSince("2009-01-03"))} Days`,
                tooltipText: "The count of days the Bitcoin blockchain has been online",
            },
            {
                label: 'Avg UTXO Age',
                value: data ? `${formatNumber(data.average_utxo_age / 365, 1)} Years` : "Loading...",
                tooltipText: "The count of days the Bitcoin blockchain has been online",
            },
            {
                label: 'Average Block Time',
                value: data ? `${formatNumber(data.average_block_interval / 60, 1)} Minutes` : "Loading...",
                tooltipText: "The average number of new blocks added to the timechain in the last 24 hours.",
            },
            {
                label: 'Nodes Found**',
                value: data ? `${formatNumber(data.total_reachable_nodes, 0)} Nodes` : "Loading...",
                tooltipText: "In the Bitcoin network, nodes fulfill a very important role. A node is a computer connected to other computers which follows rules and shares information. A 'full node' is a computer in Bitcoin's peer-to-peer network which hosts and synchronises a copy of the entire Bitcoin timechain.",
            },
            {
                label: 'Lightning Channels***',
                value: data ? `${formatNumber(data.lightning[1].channel_count, 0)} Channels` : "Loading...",
                tooltipText: "Number of Lightning channels found on the Lightning network.",
            },
            // {
            //     label: '0-Balance Addresses',
            //     value: data ? `${formatLargeNumber(data.zero_balance_addresses_all_time, 2, false)} Addresses` : "Loading...",
            //     tooltipText: "Number of addresses with a zero balance found on the Bitcoin network.",
            // },
            // {
            //     label: 'Unique Addresses',
            //     value: data ? `${formatLargeNumber(data.unique_addresses_all_time, 2, false)} Addresses` : "Loading...",
            //     tooltipText: "Number of unique addresses found on the Bitcoin network.",
            // },
        ]
        : [];

    const networkSecurityData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Mining Algorithm',
                value: 'SHA-256',
                tooltipText: "The SHA-256 hash algorithm takes input blocks of 512 bits (i.e. 64 bytes), combines the data cryptographically, and generates a 256-bit (32 byte) output. The SHA-256 algorithm consists of a relatively simple round repeated 64 times.",
            },
            {
                label: 'Timechain Size',
                value: data ? `${formatNumber(data.blockchain_size, 0)} Bytes` : "Loading...",
                tooltipText: "Size of the Bitcoin blockchain in Bytes",
            },
            {
                label: 'Hash Rate',
                value: data ? `${formatLargeNumber(data.hashrate.currentHashrate, 0, true)}H/s` : "Loading...",
                tooltipText: "The hash rate is the measuring unit of the processing power of the Bitcoin network. The Bitcoin network must make intensive mathematical operations for security purposes. When the network reached a hash rate of 10 Th/s, it meant it could make 10 trillion calculations per second.",
            },
            {
                label: '24h Mining Revenue',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.mining_revenue_usd * conversionRates[selectedCurrency], 1, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "Value of the total mining revenue (subsidy + fees) over the last 24h",
            },
            {
                label: 'Bitcoin Dominance',
                value: 'Avoid This Metric',
                tooltipText: '"Bitcoin dominance" is a scam metric devised by shitcoiners in order to steal relevance from Bitcoin. Go throw a gold bar into a pool of ever-increasing shit. Now, what is the gold dominance in the pool? Answer: It does not matter!',
                // highlighted: true,
            },
            {
                label: 'Max Supply Still 21M?',
                value: 'TRUE',
                tooltipText: "Bitcoin's maximum supply remains hard-capped at 21M BTC.",
            },
            {
                label: 'Victim of 51% Attack?',
                value: 'FALSE',
                tooltipText: "A 51% attack is a potential attack on a timechain network, where a single entity or organization is able to control the majority of the hash rate, potentially causing a network disruption. In such a scenario, the attacker would have enough mining power to intentionally exclude or modify the ordering of transactions. This metric tracks whether or not the Bitcoin network has been successfully 51% attacked.",
            },
            {
                label: 'Last TX Double-Spent?',
                value: 'FALSE',
                tooltipText: "Double-spending is a potential flaw in a digital cash scheme in which the same single digital token can be spent more than once. Unlike physical cash, a digital token consists of a digital file that can be duplicated or falsified. This metric tracks whether or not the last Bitcoin transaction was double-spent somehow.",
            },
            {
                label: 'Network Health',
                value: data ? `${formatNumber(data.total_reachable_nodes)} Nodes Found**` : "Loading...",
                tooltipText: "Bitcoin is a digital currency, a decentralized system which records transactions in a distributed ledger called a timechain... A node is a computer connected to other computers which follows rules and shares information. A 'full node' is a computer in Bitcoin's peer-to-peer network which hosts and synchronises a copy of the entire Bitcoin timechain. Nodes are essential for keeping a cryptocurrency network running.",
            },
        ]
        : [];

    const marketStatsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: '24h Exchange Flow-In',
                value: data ? `${formatNumber(data.flow_in_exchange_native_units, 0)} BTC In` : "Loading...",
                tooltipText: "The amount of Bitcoins flowing into top exchanges",
            },
            {
                label: '24h Exchange Flow-Out',
                value: data ? `${formatNumber(data.flow_out_exchange_native_units, 0)} BTC Out` : "Loading...",
                tooltipText: "The amount of Bitcoins flowing out of top exchanges",
                // highlighted:true,
            },
            {
                label: data ? data.exchange_name_0 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_0, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_0} in USD` : "Loading...",
            },
            {
                label: data ? data.exchange_name_1 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_1, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_1} in USD` : "Loading...",
            },
            {
                label: data ? data.exchange_name_2 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_2, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_2} in USD` : "Loading...",
            },
            {
                label: data ? data.exchange_name_3 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_3, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_3} in USD` : "Loading...",
            },
            {
                label: data ? data.exchange_name_4 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_4, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_4} in USD` : "Loading...",
            },
            {
                label: data ? data.exchange_name_5 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_5, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_5} in USD` : "Loading...",
            },
            {
                label: data ? data.exchange_name_8 : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(data.exchange_price_8, 2)} USD` : "Loading...",
                tooltipText: data && bitcoinPrice ? `The Bitcoin price on ${data.exchange_name_8} in USD` : "Loading...",
            },
        ]
        : [];

    const changePercentageData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Price Change (24h)',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber(data[`price_change_24h_in_currency_${selectedCurrency}`], 0)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Price Change (24h) in ${selectedCurrency.toUpperCase()}` : "Loading...",
            },
            {
                label: 'Price Change % (24h)',
                value: data && bitcoinPrice ? `${formatNumber(data[`price_change_percentage_24h_in_currency_${selectedCurrency}`], 2)}%` : "Loading...",
                tooltipText: "Price change percentage (24h)",
            },
            {
                label: 'Price Change % (7d)',
                value: data && bitcoinPrice ? `${formatNumber(data[`price_change_percentage_7d_in_currency_${selectedCurrency}`], 2)}%` : "Loading...",
                tooltipText: "Price change percentage (7d)",
            },
            {
                label: 'Price Change % (14d)',
                value: data && bitcoinPrice ? `${formatNumber(data[`price_change_percentage_14d_in_currency_${selectedCurrency}`], 2)}%` : "Loading...",
                tooltipText: "Price change percentage (14d)",
            },
            {
                label: 'Price Change % (30d)',
                value: data && bitcoinPrice ? `${formatNumber(data[`price_change_percentage_30d_in_currency_${selectedCurrency}`], 2)}%` : "Loading...",
                tooltipText: "Price change percentage (30d)",
            },
            {
                label: 'Price Change % (200d)',
                value: data && bitcoinPrice ? `${formatNumber(data[`price_change_percentage_200d_in_currency_${selectedCurrency}`], 2)}%` : "Loading...",
                tooltipText: "Price change percentage (200d)",
                // highlighted: true,
            },
            {
                label: 'Price Change % (1y)',
                value: data && bitcoinPrice ? `${formatNumber(data[`price_change_percentage_1y_in_currency_${selectedCurrency}`], 2)}%` : "Loading...",
                tooltipText: "Price change percentage (1y)",
            },
            {
                label: 'Price Change % (YTD)',
                value: data && bitcoinPrice ? `${formatNumber((data.percent_change_year_to_date * conversionRates[selectedCurrency]), 2)}%` : "Loading...",
                tooltipText: "Price change percentage (Year-to-Date)",
            },
            {
                label: 'All-time High Change %',
                value: data && bitcoinPrice ? `${formatNumber((data[`ath_change_percentage_${selectedCurrency}`]), 2)}%` : "Loading...",
                tooltipText: "All-Time High change percentage",
            },
        ]
        : [];

    const miningInfoData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Genesis Block Date',
                value: data ? data.genesis_date : "Loading...",
                tooltipText: "Genesis Block is the name of the first block of Bitcoin ever mined—thus called 'Genesis.' The Genesis Block forms the foundation of the entire Bitcoin trading system and is the prototype of all other blocks in the timechain.",
            },
            {
                label: 'Mining Algorithm',
                value: 'SHA-256',
                tooltipText: "The SHA-256 hash algorithm takes input blocks of 512 bits (i.e. 64 bytes), combines the data cryptographically, and generates a 256-bit (32 byte) output. The SHA-256 algorithm consists of a relatively simple round repeated 64 times.",
            },
            {
                label: 'Difficulty',
                value: data ? formatNumber(data.difficulty_2) : "Loading...",
                tooltipText: "Difficulty is a value used to show how hard is it to find a hash that will be lower than target defined by system. The Bitcoin network has a global block difficulty. Valid blocks must have a hash below this target. Mining pools also have a pool-specific share difficulty setting a lower limit for shares.",
            },
            {
                label: 'Predicted Next Diff.',
                value: data ? formatNumber(data.next_difficulty_estimate) : "Loading...",
                tooltipText: "Estimated next Difficulty Adjustment based on current hash rate, real block time, and other factors",
            },
            {
                label: 'Unconfirmed TXs',
                value: mempoolInfo ? `${formatNumber((mempoolInfo.size), 0)} TXs` : "Loading...",
                tooltipText: 'Number of unconfirmed transactions in the Bitcoin mempool',
                // highlighted: true,
            },
            {
                label: 'Unconfirmed TXs Fees',
                value: mempoolInfo && data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatLargeNumber((mempoolInfo.size * bitcoinPrice * conversionRates[selectedCurrency]), 2, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Total value of all unconfirmed transactions in the mempool in ${selectedCurrency.toUpperCase()}` : "Loading...",
            },
            {
                label: 'Low Fee per vByte',
                value: fees ? `${formatNumber((fees.minimumFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended Low Fee per vByte',
            },
            {
                label: 'Medium Fee per vByte',
                value: fees ? `${formatNumber((fees.hourFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended Medium Fee per vByte',
            },
            {
                label: 'High Fee per vByte',
                value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended High Fee per vByte',
            },
            {
                label: 'Bitcoin Hash Rate',
                value: data ? `${formatLargeNumber(data.hashrate.currentHashrate, 0, true)}H/s` : "Loading...",
                tooltipText: "Estimated total amount of computing power backing the Bitcoin network. Mining hashrate is a key security metric. The more hashing (computing) power in the network, the greater its security and its overall resistance to attack. Although Bitcoin's exact hashing power is unknown, it is possible to estimate it from the number of blocks being mined and the current block difficulty.",
            },
            {
                label: 'Target Block Time',
                value: '10 Minutes',
                tooltipText: "Block time defines the time it takes to mine a block. In bitcoin timechain, there is an expected block time, and an average block time. In bitcoin, the expected block time is 10 minutes.",
            },
            {
                label: 'Average Block Time 24h',
                value: data ? `${formatNumber(data.block_time / 60, 2)} Minutes` : "Loading...",
                tooltipText: "Average minutes between blocks (24h).",
            },
        ]
        : [];

    const bitcoinSupplyData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            // {
            //     label: 'Est. Patoshi Stack',
            //     value: '~1M coins',
            //     tooltipText: "Timechain analysts estimate that Nakamoto had mined about one million bitcoins (equivalent to 100,000,000,000,000 sats) before disappearing in 2010, when he handed the network alert key and control of the code repository over to Gavin Andresen. This metric has been contested and is difficult to verify.",
            // },
            {
                label: 'Percentage Issued',
                value: data ? `${formatNumber((data.circulating / 21000000) * 100, 2)}% of Supply` : "Loading...",
                tooltipText: "Total amount of bitcoins distributed to the network, represented as a percentage of the overall total supply.",
            },
            {
                label: 'Left to Mine',
                value: data ? `${formatNumber(21000000 - data.circulating, 0)} BTC` : "Loading...",
                tooltipText: "Total amount of bitcoins left to mine until all coins are distributed.",
            },
            {
                label: '25% Supply',
                value: '22 April 2010',
                tooltipText: "Date when 25% of the Bitcoin supply was mined.",
            },
            {
                label: '50% Supply',
                value: '14 December 2011',
                tooltipText: "Date when 50% of the Bitcoin supply was mined.",
            },
            {
                label: '75% Supply',
                value: '29 July 2015',
                tooltipText: "Date when 75% of the Bitcoin supply was mined.",
            },
            {
                label: '87.5% Supply',
                value: '24 May 2019',
                tooltipText: "Date when 87.5% of the Bitcoin supply was mined.",
            },
            {
                label: '90% Supply',
                value: '13 December 2021',
                tooltipText: "Date when 90% of the Bitcoin supply was mined.",
            },
            {
                label: '95% Supply',
                value: '~3 December 2025',
                tooltipText: "Date when 95% of the Bitcoin supply was mined.",
                // highlighted: true,
            },
            {
                label: '99% Supply',
                value: '~20 February 2035',
                tooltipText: "Date when 99% of the Bitcoin supply was mined.",
            },
            {
                label: '99.9% Supply',
                value: '~28 January 2048',
                tooltipText: "Date when 99.9% of the Bitcoin supply was mined.",
            },
            {
                label: 'Last Full BTC to Mine',
                value: '~2 May 2105',
                tooltipText: "Estimated date when the last full Bitcoin will have been mined.",
            },
            {
                label: 'All Bitcoins Issued',
                value: '~Between 2138-2140',
                tooltipText: "Estimated year when all Bitcoin supply will have been mined.",
            },
        ]
        : [];

    const bitcoinHalvingData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoins Mined/Hour',
                value: '~18.75 BTC / Hour',
                tooltipText: "Total amount of Bitcoins mined every hour.",
            },
            {
                label: 'Bitcoins Mined/Day',
                value: '~450 BTC / Day',
                tooltipText: "Total amount of Bitcoins mined in a single day.",
            },
            {
                label: 'Bitcoins Mined/Year',
                value: '~164.3k BTC / Year',
                tooltipText: "Total amount of Bitcoins mined in one year.",
            },
            {
                label: 'Blocks to Next Halving',
                value: data ? `${formatNumber(data.blcks_left - 1, 0)} Blocks to go` : "Loading...",
                tooltipText: "Number of blocks to go until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
            },
            {
                label: 'Seconds to Next Halving',
                value: data ? `${formatNumber(secondsLeft(data.halvening_time), 0)} Seconds` : "Loading...",
                tooltipText: "Number of seconds left until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
            },
            {
                label: 'Days Until Halving',
                value: data ? `~${formatNumber(daysLeft(data.halvening_time), 0)} Days` : "Loading...",
                tooltipText: "Estimated number of days left until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
                // highlighted:true,
            },
            {
                label: 'Est. Date / Next Halving',
                value: data ? `${data.halvening_time}` : "Loading...",
                tooltipText: "Estimated date of the next Bitcoin Halving in UTC",
            },
            {
                label: 'Current Subsidy',
                value: data ? `3.125 BTC / Block` : "Loading...",
                tooltipText: "Current block reward for successfully mining a Bitcoin block. This number is automatically halved every 210,000 blocks... or about every 4 years... in an event called a 'Bitcoin Halving.'",
            },
            {
                label: 'Post-Halving Subsidy',
                value: data ? `1.5625 BTC / Block` : "Loading...",
                tooltipText: "The number of bitcoins a miner who successfully mines a block will receive after the next Bitcoin Halving",
            },
            {
                label: 'BTC Added 4th Epoch',
                value: '1,312,500 BTC',
                tooltipText: 'Total number of Bitcoins mined in the fourth Reward Era (2020-2024)',
            },
            {
                label: 'BTC Added 3rd Epoch',
                value: '2,625,000 BTC',
                tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2016-2020)',
            },
            {
                label: 'BTC Added 2nd Epoch',
                value: '5,250,000 BTC',
                tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2012-2016)',
            },
            {
                label: 'BTC Added 1st Epoch',
                value: '10,500,000 BTC',
                tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2009-2012)',
            },
        ]
        : [];

    const futureBitcoinHalvingData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Current Era',
                value: '5th Epoch',
                tooltipText: 'The current mining epoch, as measured by count of halvings.',
            },
            {
                label: '2028 - Est. Block Subsidy',
                value: '1.5625 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2028, the Subsidy drops from 3.125 BTC to 1.5625 BTC per block.",
            },
            {
                label: '2032 - Est. Block Subsidy',
                value: '0.78125 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2032, the Subsidy drops from 1.5625 BTC to 0.78125 BTC per block.",
                // highlighted: true,
            },
            {
                label: '2036 - Est. Block Subsidy',
                value: '0.390625 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2036, the Subsidy drops from 0.78125 BTC to 0.390625 BTC per block.",
            },
            {
                label: '2040 - Est. Block Subsidy',
                value: '0.195313 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2040, the Subsidy drops from 0.390625 BTC to 0.195313 BTC per block.",
            },
            {
                label: '2044 - Est. Block Subsidy',
                value: '0.097656 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2044, the Subsidy drops from 0.195313 BTC to 0.097656 BTC per block.",
            },
            {
                label: '2048 - Est. Block Subsidy',
                value: '0.048828 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2048, the Subsidy drops from 0.097656 BTC to 0.048828 BTC per block.",
            },
            {
                label: '2052 - Est. Block Subsidy',
                value: '0.024414 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2052, the Subsidy drops from 0.048828 BTC to 0.024414 BTC per block.",
            },
            {
                label: '2056 - Est. Block Subsidy',
                value: '0.012207 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2056, the Subsidy drops from 0.024414 BTC to 0.012207 BTC per block.",
            },
            {
                label: '2060 - Est. Block Subsidy',
                value: '0.0061035 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2060, the Subsidy drops from 0.012207 BTC to 0.0061035 BTC per block.",
            },
            {
                label: '2064 - Est. Block Subsidy',
                value: '0.00305175 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2064, the Subsidy drops from 0.0061035 BTC to 0.00305175 BTC per block.",
            },
            {
                label: '2068 - Est. Block Subsidy',
                value: '0.00152587 BTC',
                tooltipText: "Bitcoins are created each time a user discovers a new block. The rate of block creation is adjusted every 2016 blocks to aim for a constant two week adjustment period (equivalent to 6 per hour). The number of bitcoins generated per block is set to decrease geometrically, with a 50% reduction every 210,000 blocks, or about four years. The result is that the number of bitcoins in existence will not exceed slightly less than 21 million. In 2068, the Subsidy drops from 0.00305175 BTC to 0.00152587 BTC per block.",
            },
        ]
        : [];

    const difficultyAdjustmentData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Min. Difficulty',
                value: '1',
                tooltipText: "The minimum difficulty value possible in the Bitcoin codebase",
            },
            {
                label: 'Theoretical Max. Diff.',
                value: '~2^224',
                tooltipText: "This is a theoretical maximum difficulty value",
            },
            {
                label: 'Mining Difficulty',
                value: data ? `${formatNumber(data.difficulty, 0)}` : "Loading...",
                tooltipText: "The difficulty is a measure of how difficult it is to mine a Bitcoin block, or in more technical terms, to find a hash below a given target. A high difficulty means that it will take more computing power to mine the same number of blocks, making the network more secure against attacks.",
            },
            {
                label: 'Predicted Next Diff.',
                value: data ? `${formatNumber(data.next_difficulty_estimate, 0)}` : "Loading...",
                tooltipText: 'Predicted next difficulty',
            },
            {
                label: 'Next Adjustment',
                value: data ? `Block ${formatNumber(data.nextretarget, 0)}` : "Loading...",
                tooltipText: "Bitcoin's difficulty is designed to adjust every 2016 blocks — or approximately every two weeks. This adjustment is based on changes in the network's hashrate, and occurs regularly in an attempt to ensure that the network continues to solve new blocks at a rate of one every 10 minutes.",
            },
            {
                label: 'Blocks Until Retarget',
                value: data && lastMempoolBlockData ? `${formatNumber((data.nextretarget - lastMempoolBlockData.height) + 1, 0)} Blocks to go` : "Loading...",
                tooltipText: "Count of remaining blocks before the next Difficulty Adjustment",
                // highlighted: true,
            },
            {
                label: 'Next Adjustment Date',
                value: da ? formatDate(da.estimatedRetargetDate, true) : "Loading...",
                tooltipText: "Estimated date of the next Difficulty Adjustment",
            },
            {
                label: 'Diff. Adj. Schedule',
                value: '2016 blocks',
                tooltipText: "The amount of blocks between Difficulty Adjustments",
            },
            {
                label: 'Diff. Adj. Frequency',
                value: '~2 weeks',
                tooltipText: "The estimated number of days between Difficulty Adjustments",
            },
            {
                label: 'Difficulty Formula',
                value: 'Diff = D1T / CT',
                tooltipText: 'The formula for calculating the mining difficulty of the Bitcoin network. "Diff" represents the new difficulty target, "D1T" denotes the previous difficulty target, and "CT" stands for the cumulative time taken to mine the most recent set of blocks. This formula ensures that the difficulty adjusts to maintain the desired average block time, which is approximately 10 minutes for Bitcoin.',
            },
            {
                label: 'Difficulty Estimator',
                value: 'https://www.bitrawr.com/difficulty-estimator',
                tooltipText: 'Estimate the Bitcoin difficulty',
                type: 'link',
                displayValue: 'Bitrawr.com',
            },
            {
                label: 'Mining Profitability',
                value: 'https://insights.braiins.com/en/profitability-calculator/',
                tooltipText: 'Calculate Bitcoin mining profitability',
                type: 'link',
                displayValue: 'Calculator',
            },
        ]
        : [];

    const ApiProvidersData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: data ? data.exchange_name_0 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_0, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_0} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_1 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_1, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_1} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_2 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_2, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_2} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_3 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_3, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_3} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_4 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_4, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_4} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_5 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_5, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_5} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_8 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_8, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_8} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? data.exchange_name_7 : "Loading...",
                value: data ? `$${formatNumber(data.exchange_price_7, 2)} USD` : "Loading...",
                tooltipText: data ? `${data.exchange_name_7} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? `Coincap` : "Loading...",
                value: data ? `$${formatNumber(data.priceUsd, 2)} USD` : "Loading...",
                tooltipText: data ? `Coincap is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? `CryptoCompare` : "Loading...",
                value: data ? `$${formatNumber(data.price, 2)} USD` : "Loading...",
                tooltipText: data ? `CryptoCompare is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            {
                label: data ? `Messari` : "Loading...",
                value: data ? `$${formatNumber(data.price_usd, 2)} USD` : "Loading...",
                tooltipText: data ? `Messari is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            },
            // {
            //     label: data ? `Blockchain.info` : "Loading...",
            //     value: data ? `$${formatNumber(data.market_price_usd, 2)} USD` : "Loading...",
            //     tooltipText: data ? `Blockchain.info is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
            // },
        ]
        : [];

    const oneBtcCanBuyData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: data && bitcoinPrice ? `Lambos @ ${formatLargeNumber((546847 * conversionRates[selectedCurrency]), 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (546847), 2)} Lambos` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of a 2020 Lamborghini Aventador (~${formatLargeNumber((546847 * conversionRates[selectedCurrency]), 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Houses @ ${formatLargeNumber(431000 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (431000), 2)} Houses` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a median U.S. existing house for all home types (~${formatLargeNumber(431000 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Used Cars @ ${formatLargeNumber(27266 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (27266), 2)} Used cars` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a used car in the U.S. (~${formatLargeNumber(27266 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: 'MtG: Black Lotus',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 3000000, 2)} Cards` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) Beta edition 'Black Lotus' card from the Magic: the Gathering trading card game (Sold for ~$3M in 2024)` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Gold @ ${formatNumber(bitcoinPrice / (data.current_price_xau * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data ? `${formatNumber((data.current_price_xau * conversionRates[selectedCurrency]), 2)} oz.` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of 1oz of gold as reported by SwissQuotes Forex Data Feed (Currently trading at ${formatNumber(bitcoinPrice / (data.current_price_xau * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Silver @ ${formatNumber(bitcoinPrice / (data.current_price_xag * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data ? `${formatNumber((data.current_price_xag * conversionRates[selectedCurrency]), 2)} oz.` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of 1oz of silver as reported by SwissQuotes Forex Data Feed (Currently trading at ${formatNumber(bitcoinPrice / (data.current_price_xag * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Oil @ ${formatNumber(data.oil_usd_ask, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / data.oil_usd_ask, 2)} Barrels` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) barrel of oil, as reported by SwissQuotes Forex Data Feed (Currently trading at $${formatNumber(data.oil_usd_ask, 2)} ${selectedCurrency.toUpperCase()} per barrel)` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Nat. Gas @ ${formatNumber(data.ngc_usd_ask, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / data.ngc_usd_ask, 2)} MCF` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one MCF of natural gas, as reported by SwissQuotes Forex Data Feed (Currently trading at $${formatNumber(data.ngc_usd_ask, 2)} ${selectedCurrency.toUpperCase()} per MCF)` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Big Macs @ ${formatNumber(5.15 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (5.15), 0)} Big Macs` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) Big Mac hamburger in the U.S. (~${formatNumber(5.15 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Top Ramen @ ${formatNumber(0.49 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (0.49), 0)} pks Ramen` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing 1 Bitcoin by the cost of one (1) package of Ramen noodles in the U.S. (~${formatNumber(0.49 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Eggs @ ${formatNumber(0.17 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (0.17), 0)} Eggs` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of an egg in the U.S. (~${formatNumber(0.17 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
            {
                label: data && bitcoinPrice ? `Milk @ ${formatNumber(4.34 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (4.34), 0)} Gallons` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a gallon of milk in the U.S. (~${formatNumber(4.34 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            },
        ]
        : [];

    // ADD CHECKS HERE
    const ownershipEconomicsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin Symbol',
                value: '₿ | BC | XBT | BTC',
                tooltipText: "There are many other cryptocurrency projects but none are Bitcoin, except for BTC... some are forked versions of bitcoin itself (like Litecoin (LTC)), others are completely separate systems and tokens (like Ethereum (ETH)), others are centralized protocols trying to work with banks (XRP), others are cheap clones of Bitcoin (Bitcoin Cash (BCH aka 'BCash' or 'BTrash') and/or Bitcoin SV (BSV aka 'Bitcoin Shit Version' or 'Bitcoin Scam Version')), but the vast majority of so-called 'altcoins' or 'shitcoins' are scams. There is only one true Bitcoin, and the name is 'Bitcoin' or 'BTC'. Anything else (like LTC, ETH, BCH or BSV) is not Bitcoin. Bitcoin = BTC!",
            },
            {
                label: '1 BTC Contains',
                value: '100,000,000 Sats',
                tooltipText: "The general unit structure of bitcoins has 1 bitcoin (BTC) equivalent to 1,000 millibitcoins (mBTC), 1,000,000 microbitcoins (μBTC), or 100,000,000 satoshis (commonly known as 'sats'). Every one (1) Bitcoin contains 100,000,000 individual units ('sats') and can be expressed with 8 decimal places (1.00000000 BTC).",
            },
            {
                label: 'Max. Units of BTC (Sats)',
                value: '2.1 Quadrillion',
                tooltipText: "Multiplying Bitcoin's max supply (20,999,999) by the number of individual units ('sats') in every bitcoin (100,000,000)... The resultng number of 2.09 quadrillion units is more money than is currently on Earth and therefore more than enough to use as a functional global currency... even with lost bitcoins.",
            },
            {
                label: 'Est. World Population',
                value: `${formatNumber(calculateCurrentPopulation(), 0)} humans`,
                tooltipText: "Estimated World Population as reported by US Census Bureau",
            },
            {
                label: '21M BTC ÷ Top 0.1%',
                value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 1000)), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 1000)",
            },
            {
                label: '21M BTC ÷ Top 1%',
                value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 100)), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 100)",
            },
            {
                label: '21M BTC ÷ Top 10%',
                value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 10)), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 10)",
            },
            {
                label: '21M BTC ÷ Person',
                value: `${formatNumber((21000000 / calculateCurrentPopulation()), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 1)",
                // highlighted: true,
            },
            {
                label: data && bitcoinPrice ? `${formatNumber((21000000 / calculateCurrentPopulation()), 4)} BTC in ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber(((21000000 / calculateCurrentPopulation()) * bitcoinPrice * conversionRates[selectedCurrency]), 2)}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Multiplying Bitcoins per Person ${formatNumber((21000000 / calculateCurrentPopulation()), 4)} by Bitcoin Price $${formatNumber(bitcoinPrice, 2)} and then converting to ${selectedCurrency.toUpperCase()}` : "Loading...",
            },
            {
                label: '21M BTC ÷ Fortune 500',
                value: `${formatNumber((21000000 / 500), 0)} BTC / Company`,
                tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of companies in the Fortune 500 list (currently 500), resulting in an allocation of approximately ${formatNumber((21000000 / 500), 0)} BTC / Company`,
            },
            {
                label: '21M BTC ÷ NYSE',
                value: `${formatNumber((21000000 / 2385), 0)} BTC / Company`,
                tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of companies listed on the New York Stock Exchange (currently around 2400), resulting in an allocation of approximately ${formatNumber((21000000 / 2385), 0)} BTC / Company`,
            },
            {
                label: '21M BTC ÷ Country',
                value: `${formatNumber((21000000 / 195), 0)} BTC / Country`,
                tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of countries in the world (currently 195), resulting in an allocation of approximately ${formatNumber((21000000 / 195), 0)} BTC / Country`,
            },

        ]
        : [];

    const lightningData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: `Lightning Channels`,
                value: data ? `${formatNumber(data.lightning_latest.latest.channel_count, 0)} Channels` : "Loading...",
                tooltipText: `The total number of channels in the Lightning Network.`,
            },
            {
                label: `Total Capacity`,
                value: data ? `${formatNumber(data.lightning_latest.latest.total_capacity / 100000000, 0)} BTC` : "Loading...",
                tooltipText: `The total capacity of the Lightning Network in bitcoins.`,
                // highlighted: true,
            },
            {
                label: `Average Capacity`,
                value: data ? `${formatNumber(data.lightning_latest.latest.avg_capacity / 100000000, 8)} BTC` : "Loading...",
                tooltipText: `The average capacity of a channel in the Lightning Network.`,
            },
            {
                label: `Average Fee Rate`,
                value: data ? `${formatNumber(data.lightning_latest.latest.avg_fee_rate, 0)} Sats` : "Loading...",
                tooltipText: `The average fee rate for transactions within the Lightning Network.`,
            },
            {
                label: `Node Count`,
                value: data ? `${formatNumber(data.lightning_latest.latest.node_count, 0)} Nodes` : "Loading...",
                tooltipText: `The total number of active nodes in the Lightning Network.`,
            },
            {
                label: `Tor Nodes`,
                value: data ? `${formatNumber(data.lightning_latest.latest.tor_nodes, 0)} Nodes` : "Loading...",
                tooltipText: `The number of nodes running over the Tor network.`,
            },
            {
                label: `Clearnet Nodes`,
                value: data ? `${formatNumber(data.lightning_latest.latest.clearnet_nodes, 0)} Nodes` : "Loading...",
                tooltipText: `The number of nodes running on the clearnet (not Tor).`,
            },
            {
                label: `Clearnet-Tor Nodes`,
                value: data ? `${formatNumber(data.lightning_latest.latest.clearnet_tor_nodes, 0)} Nodes` : "Loading...",
                tooltipText: `The number of nodes available both on the clearnet and Tor.`,
            },
            {
                label: `Unannounced Nodes`,
                value: data ? `${formatNumber(data.lightning_latest.latest.unannounced_nodes, 0)} Nodes` : "Loading...",
                tooltipText: `The number of nodes that are not publicly announced.`,
            },
        ]
        : [];

    const feesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: `Total Fees BTC 24h`,
                value: data && bitcoinPrice ? `${formatNumber(data.total_fees_last_24_hours, 8)} BTC` : "Loading...",
                tooltipText: `Total transaction fees paid on the network in BTC in the last 24 hours.`,
            },
            {
                label: `Total Fees ${selectedCurrency.toUpperCase()} 24h`,
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((data.total_fees_last_24_hours * bitcoinPrice) * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Total transaction fees paid on the network in ${selectedCurrency.toUpperCase()} in the last 24 hours.` : "Loading...",
            },
            {
                label: `Mining Fee Rev % (24h)`,
                value: data && bitcoinPrice ? `${formatNumber(data.mining_revenue_from_fees_percent_last_24_hours, 2)} %` : "Loading...",
                tooltipText: `Percentage of mining revenue that comes from transaction fees in the last 24 hours.`,
            },
            {
                label: `Avg Fee BTC`,
                value: data && bitcoinPrice ? `${formatNumber(data.average_fee_native_units, 8)} BTC` : "Loading...",
                tooltipText: `Average transaction fee on the network in BTC.`,
            },
            {
                label: data && bitcoinPrice ? `Avg Fee ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((data.average_fee_native_units * bitcoinPrice) * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Average transaction fee on the network in ${selectedCurrency.toUpperCase()}.` : "Loading...",
            },
            {
                label: `Median Fee BTC`,
                value: data && bitcoinPrice ? `${formatNumber(data.median_fee_native_units, 8)} BTC` : "Loading...",
                tooltipText: `Median transaction fee on the network in BTC.`,
            },
            {
                label: `Avg Transfer Value BTC`,
                value: data && bitcoinPrice ? `${formatNumber(data.average_transfer_value_native_units, 8)} BTC` : "Loading...",
                tooltipText: `Average value of transfers on the network in BTC.`,
            },
            {
                label: data && bitcoinPrice ? `Avg Transfer Value ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((data.average_transfer_value_native_units * bitcoinPrice) * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Average value of transfers on the network in ${selectedCurrency.toUpperCase()}.` : "Loading...",
            },
        ]
        : [];


    const btcAddressData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: `Addresses > 0.001 BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_0_001_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 0.001 BTC.`,
            },
            {
                label: `Addresses > 0.01 BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_0_01_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 0.01 BTC.`,
            },
            {
                label: `Addresses > 0.1 BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_0_1_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 0.1 BTC.`,
            },
            {
                label: `Addresses > 1 BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_1_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 1 BTC.`,
            },
            {
                label: `Addresses > 10 BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_10_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 10 BTC.`,
            },
            {
                label: `Addresses > 100 BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_100_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 100 BTC.`,
            },
            {
                label: `Addresses > 1k BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_1k_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 1,000 BTC.`,
            },
            {
                label: `Addresses > 10k BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_10k_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 10,000 BTC.`,
            },
            {
                label: `Addresses > 100k BTC`,
                value: data ? `${formatNumber(data.addresses_balance_greater_100k_native_units_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than 100,000 BTC.`,
            },
        ]
        : [];

    const usdAddressData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: `Total Addresses`,
                value: data ? `${formatNumber(data.addresses_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses`,
            },
            {
                label: `Addresses > $1`,
                value: data ? `${formatNumber(data.addresses_balance_greater_1_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $1.`,
            },
            {
                label: `Addresses > $10`,
                value: data ? `${formatNumber(data.addresses_balance_greater_10_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $10.`,
            },
            {
                label: `Addresses > $100`,
                value: data ? `${formatNumber(data.addresses_balance_greater_100_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $100.`,
            },
            {
                label: `Addresses > $1k`,
                value: data ? `${formatNumber(data.addresses_balance_greater_1k_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $1,000.`,
            },
            {
                label: `Addresses > $10k`,
                value: data ? `${formatNumber(data.addresses_balance_greater_10k_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $10,000.`,
            },
            {
                label: `Addresses > $100k`,
                value: data ? `${formatNumber(data.addresses_balance_greater_100k_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $100,000.`,
            },
            {
                label: `Addresses > $1M`,
                value: data ? `${formatNumber(data.addresses_balance_greater_1m_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $1,000,000.`,
            },
            {
                label: `Addresses > $10M`,
                value: data ? `${formatNumber(data.addresses_balance_greater_10m_usd_count, 0)}` : "Loading...",
                tooltipText: `Number of addresses with a balance greater than $10,000,000.`,
            },
        ]
        : [];

    const faqData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'General Questions',
                value: 'https://bitcoin.org/en/faq#what-is-bitcoin',
                displayValue: 'What is Bitcoin?',
                type: 'link',
                tooltipText: 'Find the answer to What is Bitcoin and more at Bitcoin.org',
            },
            {
                label: 'Legal',
                value: 'https://bitcoin.org/en/faq#is-bitcoin-legal',
                displayValue: 'Is Bitcoin legal?',
                type: 'link',
                tooltipText: 'Discover the legal status of Bitcoin around the world at Bitcoin.org',
            },
            {
                label: 'Economy',
                value: 'https://bitcoin.org/en/faq#how-are-bitcoins-created',
                displayValue: 'How are Bitcoins made?',
                type: 'link',
                tooltipText: 'Learn about the process of Bitcoin creation at Bitcoin.org',
            },
            {
                label: 'Security',
                value: 'https://bitcoin.org/en/faq#is-bitcoin-secure',
                displayValue: 'Is Bitcoin secure?',
                type: 'link',
                tooltipText: 'Explore the security features of Bitcoin at Bitcoin.org',
            },
            {
                label: 'Bitcoin Mining',
                value: 'https://bitcoin.org/en/faq#what-is-bitcoin-mining',
                displayValue: 'What is BTC mining?',
                type: 'link',
                tooltipText: 'Uncover the concept of Bitcoin mining and its role in the network at Bitcoin.org',
            },
        ]
        : [];

    const popularResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Monetary History',
                value: 'https://saifedean.com/tbs',
                displayValue: "Bitcoin Standard",
                type: 'link',
                tooltipText: 'The Bitcoin Standard by Saifedean Ammous',
            },
            {
                label: 'Bitcoin Privacy',
                value: 'https://bitcoinprivacy.guide/',
                displayValue: "Guide to Privacy",
                type: 'link',
                tooltipText: "The Beginner's Guide to Privacy by BitcoinQ&A",
            },
            {
                label: 'Bitcoin Education',
                value: 'https://21lessons.com/',
                displayValue: "21 Lessons",
                type: 'link',
                tooltipText: '21 Lessons by Gigi',
            },
            {
                label: 'Recommended',
                value: 'https://www.amazon.com/Bullish-Case-Bitcoin-Vijay-Boyapati/dp/1737204118',
                displayValue: "Bullish Case for Bitcoin",
                type: 'link',
                tooltipText: 'The Bullish Case for Bitcoin by Vijay Boyapati',
            },
            {
                label: 'Bitcoin History',
                value: 'https://www.amazon.com/dp/B08YQMC2WM',
                displayValue: "Blocksize War",
                type: 'link',
                tooltipText: 'The Blocksize War by Johnathan Bier',
            },
            {
                label: 'Financial Freedom',
                value: 'https://www.amazon.com/Sovereign-Individual-Mastering-Transition-Information/dp/0684832720/',
                displayValue: "Sovereign Individual",
                type: 'link',
                tooltipText: 'The Sovereign Individual by James Dale Davidson',
            },
            {
                label: 'Bitcoin Privacy',
                value: 'https://www.lopp.net/bitcoin-information/privacy.html',
                displayValue: "Lopp Privacy Guide",
                type: 'link',
                tooltipText: 'Lopp.net Privacy Guide by Jameson Lopp',
            },
            {
                label: 'Origin of Money',
                value: 'https://nakamotoinstitute.org/shelling-out/',
                displayValue: "Shelling Out",
                type: 'link',
                tooltipText: 'Shelling Out: Origin of Money by Nick Szabo',
            },
        ]
        : [];

    const recommendedResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Start Here',
                value: 'https://bitcoin.org/bitcoin.pdf',
                displayValue: "Bitcoin Whitepaper",
                type: 'link',
                tooltipText: "Read the original Bitcoin whitepaper by Satoshi Nakamoto on the official Bitcoin.org website",
            },
            {
                label: 'Website',
                value: 'https://bitcoincore.org/',
                displayValue: "BitcoinCore.org",
                type: 'link',
                tooltipText: "Visit the official BitcoinCore.org website for news, resources, and information about Bitcoin",
            },
            {
                label: 'Code Repository',
                value: 'https://github.com/bitcoin/bitcoin',
                displayValue: "Bitcoin Core Github",
                type: 'link',
                tooltipText: "Visit the Github repository",
            },
            {
                label: 'Run a Full Node (Bitcoin Core)',
                value: 'https://bitcoincore.org/en/download/',
                displayValue: "Download Bitcoin Core",
                type: 'link',
                tooltipText: "Learn how to run a full Bitcoin node and support the network on the official BitcoinCore.org website",
            },
            {
                label: 'Mining Guide',
                value: 'https://www.bitcoinmining.com/',
                displayValue: "BitcoinMining.com",
                type: 'link',
                tooltipText: "Find resources and information about Bitcoin mining on BitcoinMining.com",
            },
            {
                label: 'Key Management',
                value: 'https://blog.keys.casa/the-dos-and-donts-of-bitcoin-key-management/',
                displayValue: "Dos and Don'ts",
                type: 'link',
                tooltipText: "Learn about the best practices for managing your Bitcoin keys on the Keys.Casa blog",
            },
            {
                label: 'Cold Storage',
                value: 'https://sparrowwallet.com/docs/coldcard-wallet.html',
                displayValue: "Sparrow + Coldcard",
                type: 'link',
                tooltipText: "Learn how to securely store your Bitcoin using the Sparrow wallet and Coldcard hardware wallet",
            },
            {
                label: 'Bitcoin Development',
                value: 'https://bitcoincore.org/en/contribute/',
                displayValue: "Contribute",
                type: 'link',
                tooltipText: "Explore the Bitcoin source code and get involved in Bitcoin development on the official BitcoinCore.org website",
            },
        ]
        : [];

    const satoshiResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/addresses/',
                displayValue: "Addresses",
                type: 'link',
                tooltipText: 'What did Satoshi say about Bitcoin Addresses?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/banks/',
                displayValue: "Banks",
                type: 'link',
                tooltipText: 'What did Satoshi say about Banks',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/bitcoin-design/',
                displayValue: "Bitcoin Design",
                type: 'link',
                tooltipText: 'What did Satoshi say about Bitcoin Design?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/economics/',
                displayValue: "Economics",
                type: 'link',
                tooltipText: 'What did Satoshi say about Economics?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/government/',
                displayValue: "Government",
                type: 'link',
                tooltipText: 'What did Satoshi say about Government?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/micropayments/',
                displayValue: "Micropayments",
                type: 'link',
                tooltipText: 'What did Satoshi say about Micropayments?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/mining/',
                displayValue: "Mining",
                type: 'link',
                tooltipText: 'What did Satoshi say about Mining?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/proof-of-work/',
                displayValue: "Proof-of-Work",
                type: 'link',
                tooltipText: 'What did Satoshi say about Proof-of-Work?',
            },
        ]
        : [];

    const educationResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Questions and Answers',
                value: 'https://qabitcoin.davidcoen.it/product/q-a-about-bitcoin-pay-in-btc/',
                displayValue: "Q/A About Bitcoin",
                type: 'link',
                tooltipText: 'A resource with a curated list of questions and answers about Bitcoin and its various aspects.',
            },
            {
                label: 'First Principles',
                value: 'https://voskuil.org/cryptoeconomics/cryptoeconomics.pdf',
                displayValue: "Cryptoeconomics",
                type: 'link',
                tooltipText: 'An in-depth resource on the fundamentals of cryptoeconomics and how it relates to Bitcoin.',
            },
            {
                label: 'Illustrated BTC Q&A',
                value: 'https://www.manning.com/books/grokking-bitcoin',
                displayValue: "Grokking Bitcoin",
                type: 'link',
                tooltipText: 'A comprehensive guide with illustrated answers to commonly asked questions about Bitcoin.',
            },
        ]
        : [];

    const programmingResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Developer Guides',
                value: 'https://developer.bitcoin.org/devguide/',
                displayValue: "Bitcoin.org Devs",
                type: 'link',
                tooltipText: 'A collection of developer guides and documentation for working with Bitcoin.',
            },
            {
                label: 'Programming Textbook',
                value: 'https://github.com/karask/bitcoin-textbook/blob/master/bitcoin-textbook.pdf',
                displayValue: "Bitcoin Programming",
                type: 'link',
                tooltipText: 'A textbook for learning how to program with Bitcoin, with a focus on practical application.',
            },
            {
                label: 'Mastering Bitcoin',
                value: 'https://github.com/bitcoinbook/bitcoinbook/blob/develop/book.asciidoc',
                displayValue: "Understanding Concepts",
                type: 'link',
                tooltipText: 'A guide for understanding the technical concepts behind Bitcoin and how it works.',
            },
        ]
        : [];

    const miningResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Getting Started',
                value: 'https://www.bitcoinmining.com/',
                displayValue: "BitcoinMining.com",
                type: 'link',
                tooltipText: 'A resource for beginners to learn about Bitcoin mining and how to get started.',
            },
            {
                label: 'Mining & Consensus',
                value: 'https://github.com/bitcoinbook/bitcoinbook/blob/develop/ch10.asciidoc',
                displayValue: "Bitcoin Book",
                type: 'link',
                tooltipText: 'A chapter from the "Mastering Bitcoin" book that provides an in-depth look at the mining process and its role in the Bitcoin consensus mechanism.',
            },
            {
                label: 'Timestamp Security',
                value: 'https://blog.lopp.net/bitcoin-timestamp-security/',
                displayValue: "Lopp Mining Guide",
                type: 'link',
                tooltipText: 'A guide by Jameson Lopp that provides an overview of timestamp security in Bitcoin mining and how it relates to the overall security of the network.',
            },
        ]
        : [];


    const nodeResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Core Node',
                value: 'https://bitcoincore.org/en/download/',
                displayValue: "Download Bitcoin Core",
                type: 'link',
                tooltipText: 'Bitcoin Core is the reference implementation of the Bitcoin network. Running a full node allows users to verify transactions and blocks, as well as participate in network consensus and help secure the network.',
            },
            {
                label: 'Lightning Node',
                value: 'https://github.com/lightning-power-users/node-launcher',
                displayValue: "Node Launcher",
                type: 'link',
                tooltipText: 'Node Launcher is a desktop application for easily setting up a Lightning node on the Bitcoin network. Lightning nodes facilitate off-chain transactions, which can be faster and cheaper than on-chain transactions.',
            },
            {
                label: 'Node Comparisons',
                value: 'https://bitcoiner.guide/node/',
                displayValue: "Bitcoiner.Guide",
                type: 'link',
                tooltipText: 'Bitcoiner.Guide is a comprehensive guide to running a Bitcoin node. The site provides comparisons of different node implementations, as well as detailed instructions for setting up and configuring a node.',
            },
        ]
        : [];


    const bipsResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'BIP Process, revised',
                value: 'https://github.com/bitcoin/bips/blob/master/bip-0002.mediawiki',
                displayValue: "BIP-2",
                type: 'link',
                tooltipText: 'BIP stands for Bitcoin Improvement Proposal. BIP-2 outlines the process for submitting, reviewing, and accepting proposals for changes to the Bitcoin network. Anyone can submit a proposal, and the community evaluates it for technical soundness and community support.',
            },
            {
                label: 'Mnemonic Seed Phrases',
                value: 'https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki',
                displayValue: "BIP-39",
                type: 'link',
                tooltipText: 'BIP-39 defines a standard way of generating a seed phrase to back up a Bitcoin wallet. A seed phrase is a list of words that can be used to restore a wallet in case of loss or theft. BIP-39 ensures that seed phrases can be generated and recovered in a standardized way across different wallet software and hardware.',
            },
            {
                label: 'Finite Monetary Supply',
                value: 'https://github.com/bitcoin/bips/blob/master/bip-0042.mediawiki',
                displayValue: "BIP-42",
                type: 'link',
                tooltipText: 'BIP-42 proposes a change to the Bitcoin network to make the maximum supply of Bitcoin finite. Currently, the maximum supply of Bitcoin is 21 million, but this is not explicitly stated in the protocol. BIP-42 aims to make this limit explicit and immutable.',
            },
        ]
        : [];


    const moneyResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Monetary Types',
                value: 'https://www.layeredmoney.com/',
                displayValue: "Layered Money",
                type: 'link',
                tooltipText: 'A book by Nik Bhatia that explores the history and development of money and monetary systems, and how Bitcoin fits into this picture.',
            },
            {
                label: 'About BTC',
                value: 'https://www.magicbitcoinbook.com/',
                displayValue: "Magic Internet Money",
                type: 'link',
                tooltipText: 'A book by Josep Busquet that aims to explain Bitcoin in a simple and intuitive way.',
            },
            {
                label: 'Bitcoin & Money',
                value: 'http://bitcoinmoneybook.com/',
                displayValue: "Bitcoin Money Book",
                type: 'link',
                tooltipText: 'A book by Michael Caras that examines the history and properties of money and how Bitcoin is different from traditional forms of currency.',
            },
        ]
        : [];


    const economicsResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Value Ecomonics',
                value: 'https://konsensus.network/product/bitcoin-everything-divided-by-21-million/?ref=knutsvanholm',
                displayValue: "Everything ÷ 21M",
                type: 'link',
                tooltipText: 'An exploration of the economic implications of Bitcoin and its finite supply.',
            },
            {
                label: 'Monetary Theory',
                value: 'https://www.amazon.com/gp/product/1999257405/',
                displayValue: "Price of Tomorrow",
                type: 'link',
                tooltipText: 'An analysis of the impact of technological progress on the economy and the monetary system.',
            },
            {
                label: 'Ethics in Money',
                value: 'https://www.lopp.net/pdf/books/The-Ethics-of-Money-Production.pdf',
                displayValue: "Monetary Production",
                type: 'link',
                tooltipText: 'A discussion of the ethical implications of money production and the history of monetary systems.',
            },
        ]
        : [];

    const historyResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin History',
                value: 'https://www.amazon.com/dp/B08YQMC2WM',
                displayValue: "Blocksize War",
                type: 'link',
                tooltipText: 'A comprehensive history of the block size debate in Bitcoin.',
            },
            {
                label: 'Rest in Pieces',
                value: 'https://99bitcoins.com/deadcoins/',
                displayValue: "Dead Shitcoins",
                type: 'link',
                tooltipText: 'A list of cryptocurrencies that have failed and are no longer operational.',
            },
            {
                label: 'Meme Archives',
                value: 'https://bitcoinmemes.info/',
                displayValue: "BitcoinMemes.info",
                type: 'link',
                tooltipText: 'A collection of Bitcoin-related memes.',
            },
        ]
        : [];

    const freedomResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Personal Freedom',
                value: 'https://www.amazon.com/Sovereign-Individual-Mastering-Transition-Information/dp/0684832720/',
                displayValue: "Sovereign Individual",
                type: 'link',
                tooltipText: 'A book about the transition to a digital age and its impact on personal freedom and sovereignty.',
            },
            {
                label: 'Why Freedom Matters',
                value: 'https://www.amazon.com/dp/1641990503',
                displayValue: "Little Bitcoin Book",
                type: 'link',
                tooltipText: 'A collection of essays about why freedom matters and how Bitcoin promotes it.',
            },
            {
                label: 'Individualism',
                value: 'https://www.gutenberg.org/files/1250/1250-h/1250-h.htm',
                displayValue: "Anthem",
                type: 'link',
                tooltipText: 'A novella about individualism and the importance of personal freedom.',
            },
        ]
        : [];


    const tradingResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Technical Analysis 101',
                value: 'https://www.babypips.com/learn/forex',
                displayValue: "BabyPips.com",
                type: 'link',
                tooltipText: 'A beginner-friendly resource for learning technical analysis and trading strategies.',
            },
            {
                label: 'Fundamental Analysis 101',
                value: 'https://www.babypips.com/learn/forex/what-is-fundamental-analysis',
                displayValue: "BabyPips.com",
                type: 'link',
                tooltipText: 'A beginner-friendly resource for learning fundamental analysis and trading strategies.',
            },
            {
                label: "Don't Trade Bitcoin",
                value: 'https://www.fool.com/cryptocurrency/2022/04/23/why-i-dont-trade-bitcoin-in-the-short-term/',
                displayValue: "MotleyFool.com",
                type: 'link',
                tooltipText: 'An article that argues against short-term trading of Bitcoin and offers alternative investment strategies.',
            },
        ]
        : [];


    const investingResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Investment Theses',
                value: 'https://www.lopp.net/bitcoin-information/investment-theses.html',
                displayValue: 'Lopp.net',
                type: 'link',
                tooltipText: 'A collection of essays and analyses on Bitcoin investment theses by Jameson Lopp.',
            },
            {
                label: 'BTC Investing',
                value: 'https://pierre-rochard.medium.com/bitcoin-investment-theses-part-1-e97670b5389b',
                displayValue: 'Investment Theses',
                type: 'link',
                tooltipText: 'An essay on the investment theses for Bitcoin by Pierre Rochard.',
            },
            {
                label: 'MSTR Case for BTC',
                value: 'https://www.hope.com/',
                displayValue: 'Hope.com',
                type: 'link',
                tooltipText: 'A website created by Michale Saylor and MicroStrategy that presents a bullish case for Bitcoin.',
            },
        ]
        : [];


    const legalResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Global Regulations',
                value: 'https://tile.loc.gov/storage-services/service/ll/llglrd/2021687419/2021687419.pdf',
                displayValue: 'Congress Law Library',
                type: 'link',
                tooltipText: 'A report by the Law Library of Congress providing an overview of cryptocurrency regulations around the world.',
            },
            {
                label: 'Legality by Country',
                value: 'https://en.wikipedia.org/wiki/Legality_of_cryptocurrency_by_country_or_territory',
                displayValue: 'Wikipedia.org',
                type: 'link',
                tooltipText: 'A Wikipedia page providing information on the legality of cryptocurrency in various countries and territories.',
            },
            {
                label: 'Is Bitcoin Legal?',
                value: 'https://bitcoin.org/en/faq#is-bitcoin-legal',
                displayValue: 'Bitcoin.org FAQ',
                type: 'link',
                tooltipText: 'A frequently asked question on the official Bitcoin website regarding the legality of Bitcoin in various countries.',
            },
        ]
        : [];


    const bullishnessResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'High Level View',
                value: 'https://www.amazon.com/Bullish-Case-Bitcoin-Vijay-Boyapati/dp/1737204118',
                displayValue: "Bullish Case for BTC",
                type: 'link',
                tooltipText: 'A book that presents a compelling argument for the long-term bullish case for Bitcoin.',
            },
            {
                label: 'Understanding BTC',
                value: 'https://yakes.io/book/',
                displayValue: "The 7th Property",
                type: 'link',
                tooltipText: 'A book that explores the unique properties of Bitcoin and why it is so important.',
            },
            {
                label: 'Reasons Why',
                value: 'https://whybitcoinbook.com/',
                displayValue: "Why Bitcoin?",
                type: 'link',
                tooltipText: 'A book that explains why Bitcoin matters and why it has the potential to change the world.',
            },
            {
                label: 'First Principles',
                value: 'https://voskuil.org/cryptoeconomics/cryptoeconomics.pdf',
                displayValue: "Cryptoeconomics",
                type: 'link',
                tooltipText: 'A book that provides an introduction to the principles of cryptoeconomics, which underpin Bitcoin and other cryptocurrencies.',
            },
            {
                label: 'Illustrated BTC Q&A',
                value: 'https://www.manning.com/books/grokking-bitcoin',
                displayValue: "Grokking Bitcoin",
                type: 'link',
                tooltipText: 'A book that explains Bitcoin using easy-to-understand illustrations and real-world examples.',
            },
        ]
        : [];

    const gameTheoryResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin Adoption',
                value: 'https://medium.com/@beautyon_/bitcoin-adoption-and-conway-s-game-of-life-54ca71db7b88',
                displayValue: "Game of Life",
                type: 'link',
                tooltipText: `An article that explores the adoption of Bitcoin in the context of Conway's Game of Life, a cellular automaton that demonstrates how simple rules can lead to complex, emergent behavior.`,
            },
            {
                label: 'Banning Bitcoin',
                value: 'https://blogs.cornell.edu/info2040/2021/09/19/the-global-prisoners-dilemma-of-crypto/',
                displayValue: "Prisoner's Dilemma",
                type: 'link',
                tooltipText: `An article discussing the global prisoner's dilemma of banning cryptocurrencies like Bitcoin, highlighting the game theory implications for countries considering regulation.`,
            },
            {
                label: 'Game Theory: Bitcoin',
                value: 'https://bitcoinmagazine.com/culture/the-bitcoin-dilemma',
                displayValue: "BitcoinMagazine.com",
                type: 'link',
                tooltipText: 'An article examining Bitcoin through the lens of game theory, including the incentives that drive its adoption and how its decentralized nature impacts various players in the ecosystem.',
            },
            {
                label: 'BTC Game Theory',
                value: 'https://www.nasdaq.com/articles/a-look-at-the-game-theory-of-bitcoin-2021-09-13',
                displayValue: "Nasdaq.com",
                type: 'link',
                tooltipText: 'An article that provides an overview of the game theory principles behind Bitcoin, discussing its consensus mechanism, mining, and the role of incentives in its design.',
            },
            {
                label: 'BTC Possibilities',
                value: 'https://bitcoinmagazine.com/culture/bitcoin-has-endless-possibilities',
                displayValue: "BitcoinMagazine.com",
                type: 'link',
                tooltipText: 'An article that delves into the numerous possibilities of Bitcoin, touching upon its potential to disrupt traditional financial systems and its role as a catalyst for innovation.',
            },
        ]
        : [];

    const securityResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin Support',
                value: 'https://guides.bitcoinsupport.com/guides/',
                displayValue: "Bull Bitcoin",
                type: 'link',
                tooltipText: 'Bull Bitcoin offers a range of guides to help you secure your bitcoin, including how to create and manage your own cold storage wallet.',
            },
            {
                label: 'Beginner Security',
                value: 'https://www.lopp.net/pdf/Jolly_Rogers_Security_Guide_for_Beginners.pdf',
                displayValue: "Lopp.net",
                type: 'link',
                tooltipText: 'Lopp.net offers a beginner-friendly guide to bitcoin security, covering everything from securing your computer to safely storing your private keys.',
            },
            {
                label: 'Security Guides',
                value: 'https://www.lopp.net/bitcoin-information/security.html',
                displayValue: "Lopp.net",
                type: 'link',
                tooltipText: 'Lopp.net also provides a range of advanced security guides for bitcoin, including securing multisignature wallets and using hardware wallets.',
            },
            {
                label: 'Security & Hardening',
                value: 'https://github.com/decalage2/awesome-security-hardening',
                displayValue: "Awesome Security",
                type: 'link',
                tooltipText: 'Awesome Security is a collection of security resources and guides, including those specifically related to hardening your computer and online accounts.',
            },
            {
                label: 'Deep Dive',
                value: 'https://blog.lopp.net/bitcoins-security-model-a-deep-dive/',
                displayValue: "BTC Security Model",
                type: 'link',
                tooltipText: 'Lopp.net also provides an in-depth look at the security model of bitcoin, covering everything from its decentralized network to its cryptography and incentives.',
            },
        ]
        : [];

    const privacyResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'About PGP',
                value: 'https://www.philzimmermann.com/EN/essays/WhyIWrotePGP.html',
                displayValue: "PhilZimmermann.com",
                type: 'link',
                tooltipText: 'Find out why Phil Zimmermann wrote PGP',
            },
            {
                label: 'Privacy Matters',
                value: 'https://www.schneier.com/blog/archives/2006/05/the_value_of_pr.html',
                displayValue: "Value of Privacy",
                type: 'link',
                tooltipText: 'An article on the importance of privacy.',
            },
            {
                label: 'Recommended Reading',
                value: 'https://www.reddit.com/r/privacy/wiki/recommended_reading',
                displayValue: "Reddit.com",
                type: 'link',
                tooltipText: 'A list of recommended reading on privacy.',
            },
            {
                label: 'Privacy Guides',
                value: 'https://www.lopp.net/bitcoin-information/privacy.html',
                displayValue: "Lopp.net",
                type: 'link',
                tooltipText: 'Guides on how to improve your privacy.',
            },
            {
                label: 'Privacy Guides',
                value: 'https://bitcoin.org/en/protect-your-privacy',
                displayValue: "Bitcoin.org",
                type: 'link',
                tooltipText: 'Guides on how to protect your privacy with Bitcoin.',
            },
        ]
        : [];

    const readingData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Start Here',
                value: 'https://bitcoin.org/bitcoin.pdf',
                displayValue: 'Bitcoin Whitepaper',
                type: 'link',
                tooltipText: 'The original whitepaper written by Satoshi Nakamoto describing the concept of Bitcoin',
            },
            {
                label: 'Understanding Money',
                value: 'https://saifedean.com/book/',
                displayValue: 'The Bitcoin Standard',
                type: 'link',
                tooltipText: 'A book that explains Bitcoin in the context of monetary history and economics',
            },
            {
                label: 'History of Money',
                value: 'https://nakamotoinstitute.org/shelling-out/',
                displayValue: 'Shelling Out',
                type: 'link',
                tooltipText: 'A book that explores the history of money and how Bitcoin fits into it',
            },
            {
                label: 'Technical Resources',
                value: 'https://aantonop.com/product/mastering-bitcoin-2nd-edition-ebook/',
                displayValue: 'Mastering Bitcoin',
                type: 'link',
                tooltipText: 'A book that provides a technical introduction to Bitcoin and the underlying technology',
            },
            {
                label: 'List of Dead Shitcoins',
                value: 'https://99bitcoins.com/deadcoins/',
                displayValue: 'DeadCoins.com',
                type: 'link',
                tooltipText: 'A website that lists dead and abandoned cryptocurrencies',
            },
        ]
        : [];

    const resourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Official Website',
                value: 'https://bitcoincore.org',
                displayValue: 'BitcoinCore.org',
                type: 'link',
                tooltipText: 'The official website of the Bitcoin project',
            },
            {
                label: 'Information',
                value: 'https://www.lopp.net/bitcoin-information.html',
                displayValue: 'Lopp Resources',
                type: 'link',
                tooltipText: 'A collection of resources about Bitcoin curated by Jameson Lopp',
            },
            {
                label: 'Current Events',
                value: 'https://www.youtube.com/channel/UCVfMv5xEfrafk1rSthJ0t9g',
                displayValue: 'Pleb Underground',
                type: 'link',
                tooltipText: 'A YouTube channel focused on Bitcoin news and events',
            },
            // {
            //     label: 'Bitcoin Glossary',
            //     value: 'https://btclexicon.com',
            //     displayValue: 'BTCLexicon.com',
            //     type: 'link',
            //     tooltipText: 'A glossary of Bitcoin terms and definitions',
            // },
            {
                label: 'Quotes by Satoshi Nakamoto',
                value: 'https://nakamotoinstitute.org/',
                displayValue: 'Nakamoto Institute',
                type: 'link',
                tooltipText: 'A collection of writings and quotes by Bitcoin creator Satoshi Nakamoto',
            },
        ]
        : [];

    const bitcoinFixesThisData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin fixes Gold',
                value: 'By making it digital',
                tooltipText: "Gold cannot move freely around the world; it is heavy, cannot be backed-up or encrypted, it's hard to verify that it's real, and it's difficult, expensive, and slow to move around. Bitcoin fixes all of these problems, plus provides programmability and other features necessary for the modern electronic world.",
            },
            {
                label: 'Bitcoin fixes Bank Holidays',
                value: 'By being open 24/7',
                tooltipText: "Bitcoin never closes. Bitcoin users can transact whenever they want, wherever they want, without anyone else's permission.",
            },
            {
                label: 'Bitcoin fixes Bailouts',
                value: 'Harder to print money',
                tooltipText: "Currently, most governments can print as much money as they want, and they use this capability to bail out financial institutions when their risky bets don't pan out. Ultimately taxpayers and ordinary citizens pay for this via inflation (see the Cantillon Effect). In a world where bitcoin adoption is high, governments would not have the ability to print money at anywhere near the scale they can now, thus large-scale financial bailouts would be impossible.",
            },
            {
                label: 'Bitcoin fixes Inflation',
                value: 'Hard-capped supply',
                tooltipText: "Since governments can currently print more money whenever they want, politicians will always promise things to the electorate and rely on central banks to fund the spending through inflation. The citizenry will pay for this via the Cantillon Effect, which is one reason why inflation is bad. In a world where bitcoin adoption is high, governments couldn't print more money at will and therefore excessive inflation would be impossible.",
            },
            {
                label: 'Bitcoin fixes Volatility',
                value: 'Low time preference',
                tooltipText: "A theme in the fiat economy is artificial suppression of risk and volatility by central banks and politicians, which inevitably leads to massive harmful volatility later on. Bitcoin forces people to accept natural short term volatility in order to forgo the harmful much larger long-term black-swan volatility events. This leads to greater long-run prosperity.",
            },
        ]
        : [];

    const walletsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Cold Storage',
                value: 'https://sparrowwallet.com/docs/coldcard-wallet.html',
                displayValue: 'Coldcard + Sparrow',
                type: 'link',
                tooltipText: 'A hardware wallet that offers the highest level of security by keeping your private keys offline, while using an air-gapped computer to sign transactions.',
            },
            {
                label: 'Hardware Wallet',
                value: 'https://coldcardwallet.com/',
                displayValue: 'Coldcard',
                type: 'link',
                tooltipText: 'A hardware wallet that offers advanced security features, including the ability to use multi-sig and the option to store your seed phrase offline.',
            },
            {
                label: 'Mobile Wallet',
                value: 'https://breez.technology/',
                displayValue: 'Breez Mobile',
                type: 'link',
                tooltipText: 'A mobile wallet that offers Lightning Network capabilities, making it easy to send and receive bitcoin quickly and cheaply.',
            },
            {
                label: 'Desktop Wallet',
                value: 'https://sparrowwallet.com/',
                displayValue: 'Sparrow Wallet',
                type: 'link',
                tooltipText: 'A desktop wallet that offers advanced security features, including the option to use multi-sig and to store your seed phrase offline.',
            },
            {
                label: 'Physical Wallet',
                value: 'https://seedplate.com/',
                displayValue: 'Seed Plate',
                type: 'link',
                tooltipText: 'A physical backup solution that allows you to store your seed phrase in a durable and tamper-resistant metal plate.',
            },
        ]
        : [];

    const visualizationsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Live Node Map',
                value: 'https://bitnodes.io/nodes/live-map/',
                displayValue: 'Bitnodes.io',
                type: 'link',
                tooltipText: 'Visualize live Bitcoin nodes on a map',
            },
            {
                label: 'Block Explorer',
                value: 'https://bitfeed.live/',
                displayValue: 'Bitfeed.live',
                type: 'link',
                tooltipText: 'Explore Bitcoin blocks, transactions and addresses',
            },
            {
                label: 'Timechain History',
                value: 'https://timechaincalendar.com/',
                displayValue: 'TimechainCalendar.com',
                type: 'link',
                tooltipText: 'Visualize the history of the Bitcoin blockchain',
            },
            {
                label: 'Bitcoin Transactions',
                value: 'http://bitcoin.interaqt.nl/',
                displayValue: 'Bitcoin.interaqt.nl',
                type: 'link',
                tooltipText: 'Visualize Bitcoin transactions as they happen in real time',
            },
            {
                label: 'Chain Visualization',
                value: 'http://bitcoincity.info/',
                displayValue: 'BitcoinCity.info',
                type: 'link',
                tooltipText: 'Visualize Bitcoin blocks, transactions and network data in a 3D cityscape',
            },
        ]
        : [];

    // Function to filter content based on the current filter state
    const filterContent = (contentFilters, showOnAll = true) => {
        // If the current filter is 'All', return the showOnAll parameter
        if (filter === 'All') {
            return showOnAll;
        } else {
            // Otherwise, check if the contentFilters array includes the current filter
            return contentFilters.includes(filter);
        }
    };

    // Log the current data for debugging purposes
    // console.log({
    //     bitcoinPrice,
    //     data,
    //     lastMempoolBlockData,
    //     fees,
    //     mempoolInfo,
    //     da,
    //     blocks,
    // });

    if (!data || !bitcoinPrice || !lastMempoolBlockData || !fees || !mempoolInfo || !da || !conversionRates || !selectedCurrency) {
        return <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="85vh" ><CustomSpinner /><Text mt={4}>Building interface...</Text></Box>;
    }

    return (
        <ContentWrapper>
{/* <TcsAlert /> */}


            <DataBanner
                data={data}
                formatNumber={formatNumber}
                formatLargeNumber={formatLargeNumber}
                bitcoinPrice={bitcoinPrice}
                lastMempoolBlockData={lastMempoolBlockData}
                fees={fees}
                mempoolInfo={mempoolInfo}
            />
            <div className="navbar-container">
                <Navbar filter={filter} setFilter={setFilter} />
            </div>

            {filterContent([''], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >LIVE BITCOIN PRICE DATA</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >
                {filterContent([''], true) && (
                    lastBlockTableData ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 24.5%' }}
                            className={animateLastBlock ? 'orange-border' : ''}
                        >
                            <DataTable
                                title={dataTableTitle}
                                subtitle='What happened in the last block?'
                                icon={<FontAwesomeIcon icon={faCube} fontSize="20px" />}
                                rows={lastBlockTableData}
                                animateLastBlock={animateLastBlock}
                                link="https://bitfeed.live/"
                                linkLabel='Explore Block'
                                footerIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                                showIcon={true}
                                rowsPerPage={9} // adjust for pagination                              
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent([''], true) && (
                    data.bcprice ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 49%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW="100%"
                        >
                            <HashRatePriceTile
                                title="Bitcoin Price & Hashrate"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                                uniqueChartId="chart-5"
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent([''], true) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                        >
                            <RewardsToFeesTile
                                uniqueChartId="RewardsToFeesTile-9"
                                title="Fees vs. Subsidy"
                                subtitle="What % of recent rewards were fees?"
                                icon={<FontAwesomeIcon icon={faChartPie} fontSize="20px" />}
                                data={data}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                            />

                        </Flex>
                    ) : <CustomSpinner />
                )}


            </Flex>

            {filterContent(['Network'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN NETWORK</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network'], false) && (
                    <Flex flex={{ base: '1 0 100%', md: '1 0 32.75%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="BTC Network"
                            subtitle="Recommended BTC resources"
                            icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                            rows={recommendedResourcesData}
                            link=""
                        />
                    </Flex>
                )}

                {filterContent(['Network'], false) && (
                    data.transactions ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 66.25%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsAndHashRateTile
                                uniqueChartId="TransactionsAndHashRateTile-2"
                                title="Transactions / Hashrate"
                                subtitle="Logarithmic Scale"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}


            </Flex>

            {filterContent(['Mining'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN MINING</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >


                {filterContent(['Mining'], false) && (
                    data.bcprice ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 49.54%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRatePriceTile
                                uniqueChartId="HashRatePriceTile-2"
                                title="Bitcoin Mining"
                                subtitle="Hashrate and Price"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 49.54%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <RewardsToFeesTile
                                uniqueChartId="RewardsToFeesTile-2"
                                title="Fees vs. Subsidy"
                                subtitle="Fee percentage of mining rewards over the last 144 blocks"
                                icon={<FontAwesomeIcon icon={faChartPie} fontSize="20px" />}
                                data={data}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}


            </Flex>

            {filterContent(['Market'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN MARKET</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Market'], false) && (
                    data.exchange_trade_volume ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <VolumeTile
                                uniqueChartId="VolumeTile-5"
                                title="BTC Market: Volume"
                                subtitle="Trading volume on major bitcoin exchanges"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Market'], false) && (
                    data.bcprice ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 48.54%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <ChartTile
                                uniqueChartId="ChartTile-5"
                                title="Bitcoin Price Chart"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Market'], false) && (
                    data.bcprice ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <MarketCapTile
                                uniqueChartId="MarketCapTile-5"
                                title="Bitcoin Market Cap"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}


            </Flex>

            {filterContent(['Charts'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN CHARTS</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 48.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenTile
                                uniqueChartId="PoolsTopTenTile-2"
                                title="Bitcoin Charts"
                                subtitle="Top pools by block count (3y)"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatNumber={formatNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data.btc_mempool_size ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 48.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRateMempoolSizeTile
                                uniqueChartId="HashRateMempoolSizeTile-2"
                                title="Hashrate / Mempool"
                                subtitle="1-hour rolling average"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}


            </Flex>

            {filterContent(['Resources'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN RESOURCES</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="Bitcoin Resources"
                            subtitle="Popular Bitcoin resources"
                            icon={<FontAwesomeIcon icon={faFireAlt} fontSize="20px" />}
                            rows={popularResourcesData}
                            link=""
                        />
                    </Flex>
                )}

                {filterContent(['Resources'], false) && (
                    <Flex flex={{ base: '1 0 100%', md: '1 0 49.54%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="New to Bitcoin?"
                            subtitle="Recommended BTC resources"
                            icon={<FontAwesomeIcon icon={faPlay} fontSize="20px" />}
                            rows={recommendedResourcesData}
                            link=""
                        />
                    </Flex>
                )}

                {filterContent(['Resources'], false) && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 1 24%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="Writings of Satoshi"
                            subtitle="Satoshi's writings on various topics"
                            icon={<FontAwesomeIcon icon={faPenFancy} fontSize="20px" />}
                            rows={satoshiResourcesData}
                            link=""
                        />
                    </Flex>
                )}

            </Flex>

            {/* {filterContent(['Mining'], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >LATEST BITCOIN BLOCKS</Text>
            )} */}

            {filterContent(['Mining'], true) && (
                data && bitcoinPrice ? ( // ensure the check for correct data
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >

                        <RecentBlocks animate={animateLastBlock} />


                    </Flex>
                ) : <CustomSpinner />
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Mining', 'Charts'], true) && (
                    lastMempoolBlockData ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                        >
                            <DifficultyAdjustmentTile
                                title="Next Difficulty Adjust."
                                subtitle="When is the next diff. adjustment?"
                                icon={<FontAwesomeIcon icon={faGears} fontSize="20px" />}
                                lastMempoolBlockData={lastMempoolBlockData}
                                da={da}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Charts'], true) && (
                    lastMempoolBlockData && da ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                        >
                            <ProgressTile
                                title="Next Bitcoin Halving"
                                subtitle="When is the next Bitcoin halving?"
                                icon={<FontAwesomeIcon icon={faScissors} fontSize="20px" />}
                                lastMempoolBlockData={lastMempoolBlockData}
                                da={da}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

            </Flex>


            {filterContent([''], true) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <DataTileTargetBlockTime data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileRealBlockTime data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileCirculatingSupply data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileMaximumSupply data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileLeftToMine data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTilePercentMined data={data} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    </Flex>
                ) : <CustomSpinner />
            )}


            {filterContent(['Network'], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <NetworkTileTimechainReachable data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileAge data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileAvgUtxoAge data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileBlocks24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileBtcPerCountry data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileRealBlockTime data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Market'], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <MarketTileExchangeFlowIn data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileExchangeFlowOut data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileAllTimeHigh data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileAllTimeLow data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileAthPercentage data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileBtcToGold data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Mining'], false) && (
                data && bitcoinPrice ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <MiningTileHashRate data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileDifficulty data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileRewardBtc data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileRewardFees data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileMempoolTxs mempoolInfo={mempoolInfo} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileMempoolSize formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} mempoolInfo={mempoolInfo} />
                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent([''], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Resources'], false) && (
                <Flex
                    flexDirection={{ base: 'row', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 3, md: 3 }}
                >
                    <ResourceTileWhitepaper flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileShellingOut flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileCypherpunkManifesto flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileDevGuides flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileBips flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileWiki flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                </Flex>
            )}

            {filterContent(['Market'], true) && (
                data && bitcoinPrice ? (
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >


                        <DataTable
                            title="Bitcoin vs Fiat"
                            subtitle="Live Bitcoin price in various currencies***"
                            icon={<FontAwesomeIcon icon={faMedal} fontSize="20px" />}
                            // highlightedData={highlightedDataMarket}
                            rows={currencies1Data}
                            link=""
                            animate={animateLastBlock}
                        />


                        <DataTable
                            title=""
                            subtitle=""
                            icon=""
                            // highlightedData={highlightedDataMarket}
                            rows={currencies2Data}
                            link=""
                            animate={animateLastBlock}
                        />



                        <DataTable
                            title=""
                            subtitle=""
                            icon=""
                            rows={currencies3Data}
                            link=""
                            animate={animateLastBlock}
                        />


                        <DataTable
                            title=""
                            subtitle=""
                            icon=""
                            rows={currencies4Data}
                            link=""
                            animate={animateLastBlock}
                        />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent([''], true) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <WordTile
                            title="What is Bitcoin?"
                            subtitle="A peer-to-peer electronic cash system"
                            icon={<FontAwesomeIcon icon={faCircleQuestion} fontSize="20px" />}
                            body="Bitcoin is a unique, decentralized, peer-to-peer transaction network featuring a finite, programmable native digital currency. Bitcoin (BTC), the intrinsic asset of the Bitcoin blockchain, operates autonomously, free from central authorities or administrators. The Bitcoin network's stability is achieved through the collaborative efforts of full nodes, miners, and developers. Its foundation lies in a user-established and user-agreed social contract, which is strengthened by the strategic use of game theory and sophisticated cryptography. Bitcoin's design ensures censorship-resistance, permissionless access, and a reliable store of value, making it an innovative and secure form of digital money."
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 0 20%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <ImageTile
                            src="https://timechainstats.com/img/img-11d.png"
                            width="240"
                            height="240"
                            link="https://bitcoincore.org"
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <QuoteTile
                            title="Satoshi Nakamoto Said..."
                            subtitle="Random quote from the creator(s) of Bitcoin"
                            icon={<FontAwesomeIcon icon={faUserSecret} fontSize="20px" />}
                        />
                    </Flex>
                </Flex>
            )}

            {filterContent([''], true) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <DataTable
                        title="What is a Sat Worth?"
                        subtitle="How much fiat can a sat buy?"
                        icon={<FontAwesomeIcon icon={faRightLeft} fontSize="20px" />}
                        rows={whatIsASatWorthData}
                        animate={animateLastBlock}
                    />

                    <DataTable
                        title="What is Fiat Worth?"
                        subtitle="How much BTC can fiat currency buy?"
                        icon={<FontAwesomeIcon icon={faDollar} fontSize="20px" />}
                        rows={whatIsAUsdWorthData}
                        animate={animateLastBlock}
                    />

                    <DataTable
                        title="Bitcoin Breakdown"
                        subtitle="Dividing Bitcoin's total supply by group"
                        icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                        rows={bitcoinBreakdownData}
                        animate={animateLastBlock}
                    />
                </Flex>
            )}

            {filterContent([''], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >LATEST BITCOIN METRICS</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >
                {filterContent(['Charts'], true) && (
                    data.exchange_trade_volume ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <VolumeTile
                                uniqueChartId="VolumeTile-1"
                                title="Exchange Volume"
                                subtitle="Trading volume on major bitcoin exchanges"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Charts'], true) && (
                    data.bcprice ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <ChartTile
                                uniqueChartId="ChartTile-2"
                                title="Bitcoin Price Chart"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}


                {filterContent(['Charts'], true) && (
                    data.bcprice ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <MarketCapTile
                                uniqueChartId="MarketCapTile-1"
                                title="Bitcoin Market Cap"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesTile
                                uniqueChartId="LightningNodesTile-2"
                                title="Lightning Nodes"
                                subtitle="Breakdown of Lightning nodes"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesByCountryTile
                                uniqueChartId="LightningNodesByCountryTile-2"
                                title="Lightning by Country"
                                subtitle="Top 12 by node count or capacity"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent([''], true) && (
                    data.transactions ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsTile
                                title="Transactions"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 100%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRateTile
                                title="Hash Rate"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network'], true) && (
                    <DataTable
                        title="Bitcoin Network"
                        subtitle="Information about the BTC network"
                        icon={<FontAwesomeIcon icon={faBitcoinSign} fontSize="20px" />}
                        rows={bitcoinNetworkData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Network Security"
                        subtitle="Protecting the Bitcoin timechain"
                        icon={<FontAwesomeIcon icon={faLock} fontSize="20px" />}
                        rows={networkSecurityData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <DataTable
                            title="Lightning Network"
                            subtitle="Bitcoin Lightning Network stats"
                            icon={<FontAwesomeIcon icon={faBoltLightning} fontSize="20px" />}
                            rows={lightningData}
                            link=""
                            animate={animateLastBlock}
                        />
                    ) : <CustomSpinner />
                )}

                {filterContent(['Market', 'Network'], false) && (
                    data ? (
                        <DataTable
                            title="Network Fees"
                            subtitle="Data about fees across the network"
                            icon={<FontAwesomeIcon icon={faCoins} fontSize="20px" />}
                            rows={feesData}
                            link=""
                            animate={animateLastBlock}
                        />
                    ) : <CustomSpinner />
                )}

                {filterContent(['Market'], true) && (
                    <DataTable
                        title="Market Data"
                        subtitle="BTC~USD exchange price data"
                        icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                        // highlightedData={highlightedDataMarket}
                        rows={marketStatsData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Market'], true) && (
                    <DataTable
                        title="Change Percentages"
                        subtitle="Bitcoin price change percentages"
                        icon={<FontAwesomeIcon icon={faPercent} fontSize="20px" />}
                        rows={changePercentageData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Mining'], true) && (
                    <DataTable
                        title="Mining Info"
                        subtitle="Informative datapoints: BTC mining"
                        icon={<FontAwesomeIcon icon={faGem} fontSize="20px" />}
                        rows={miningInfoData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Bitcoin Supply"
                        subtitle="Distribution schedule and other details"
                        icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                        rows={bitcoinSupplyData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Bitcoin Halving"
                        subtitle="Details about Bitcoin halvings"
                        icon={<FontAwesomeIcon icon={faScissors} fontSize="20px" />}
                        rows={bitcoinHalvingData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Future Bitcoin Halvings"
                        subtitle="Track upcoming BTC halving events"
                        icon={<FontAwesomeIcon icon={faCalendarAlt} fontSize="20px" />}
                        rows={futureBitcoinHalvingData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <AvgRewardsOverTimeTile
                                title="Average Rewards"
                                subtitle="Average mining rewards"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <RewardsToFeesTile
                                uniqueChartId="RewardsToFeesTile-1"
                                title="Mining Reward vs. Fees"
                                subtitle="Fee percentage of reward over the last 144 blocks"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <RewardsToFeesBarTile
                                title="Reward to Fees"
                                subtitle="Fee percentage of reward over the last 144 blocks"
                                icon={<FontAwesomeIcon icon={faBarChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenTile
                                uniqueChartId="PoolsTopTenTile-1"
                                title="Mining Pools Top 10"
                                subtitle="Top pools by block count (3y)"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatNumber={formatNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenHashPieTile
                                uniqueChartId="PoolsTopTenHashPieTile-1"
                                title="Mining Pools Top 10 Hash"
                                subtitle="Top pools by hashpower"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenPieTile
                                uniqueChartId="PoolsTopTenPieTile-1"
                                title="Mining Pools Top 10 Blocks"
                                subtitle="Top pools by block count (3y)"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatNumber={formatNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            width="100%"
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <AvgFeesOverTimeTile
                                title="Avg Fees Value (USD)"
                                subtitle="Average value of mining fees over time"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Market'], false) && (
                    data.btc_mempool_size ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRateMempoolSizeTile
                                uniqueChartId="HashRateMempoolSizeTile-1"
                                title="Hashrate / Mempool"
                                subtitle="1-hour rolling average"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Difficulty Adjustment"
                        subtitle="Stats: Bitcoin mining difficulty"
                        icon={<FontAwesomeIcon icon={faSliders} fontSize="20px" />}
                        rows={difficultyAdjustmentData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Market'], true) && (
                    <DataTable
                        title="API Providers"
                        subtitle="BTC~USD powered by top APIs"
                        icon={<FontAwesomeIcon icon={faDatabase} fontSize="20px" />}
                        rows={ApiProvidersData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Market'], true) && (
                    data || bitcoinPrice ? (
                        <DataTable
                            title="1 BTC Can Buy..."
                            subtitle="What can you buy with 1 Bitcoin?"
                            icon={<FontAwesomeIcon icon={faBasketShopping} fontSize="20px" />}
                            rows={oneBtcCanBuyData}
                            link=""
                            animate={animateLastBlock}
                        />
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining', 'Market'], true) && (
                    <DataTable
                        title="Ownership Economics"
                        subtitle="Dividing BTC's total supply by world population"
                        icon={<FontAwesomeIcon icon={faGlobeAsia} fontSize="20px" />}
                        rows={ownershipEconomicsData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Market', 'Charts'], false) && (
                    data.transactions ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsTile
                                title="Transactions"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining', 'Market', 'Charts'], false) && (
                    data.transactions ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsAndHashRateTile
                                uniqueChartId="TransactionsAndHashRateTile-1"
                                title="Transactions / Hashrate"
                                subtitle="Logarithmic Scale"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining', 'Market', 'Charts'], false) && (
                    data.bcprice ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRatePriceTile
                                title="Price / Hashrate"
                                subtitle="Logarithmic Scale"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                                uniqueChartId="chart-1"
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesTile
                                uniqueChartId="LightningNodesTile-1"
                                title="Lightning Nodes"
                                subtitle="Breakdown of Lightning nodes"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesByCountryTile
                                uniqueChartId="LightningNodesByCountryTile-1"
                                title="Lightning by Country"
                                subtitle="Top 12 by node count or capacity"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningCapacityByCountryTile
                                title="Lightning by Country"
                                subtitle="Top 12 by node count or capacity"
                                icon={<FontAwesomeIcon icon={faTimeline} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

            </Flex>

            {filterContent(['Network', 'Market'], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >
                        <DataTable
                            title="Distribution (BTC)"
                            subtitle="Address grouped by HODL totals in BTC"
                            icon={<FontAwesomeIcon icon={faBtc} fontSize="20px" />}
                            rows={btcAddressData}
                            animate={animateLastBlock}
                        />

                        <DataTable
                            title="Distribution (USD)"
                            subtitle="Address grouped by HODL totals in USD"
                            icon={<FontAwesomeIcon icon={faDollar} fontSize="20px" />}
                            rows={usdAddressData}
                            animate={animateLastBlock}
                        />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Resources'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >MORE PUBLICATIONS</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Privacy"
                        subtitle="Info about personal privacy"
                        icon={<FontAwesomeIcon icon={faBtc} fontSize="20px" />}
                        rows={privacyResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Security"
                        subtitle="Bitcoin security tips"
                        icon={<FontAwesomeIcon icon={faLock} fontSize="20px" />}
                        rows={securityResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Money"
                        subtitle="Learn about money"
                        icon={<FontAwesomeIcon icon={faCoins} fontSize="20px" />}
                        rows={moneyResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Economics"
                        subtitle="Bitcoin & Economics"
                        icon={<FontAwesomeIcon icon={faMoneyBill} fontSize="20px" />}
                        rows={economicsResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="History"
                        subtitle="Bitcoin History"
                        icon={<FontAwesomeIcon icon={faHistory} fontSize="20px" />}
                        rows={historyResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Freedom"
                        subtitle="Personal and financial freedom"
                        icon={<FontAwesomeIcon icon={faHandFist} fontSize="20px" />}
                        rows={freedomResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Run a Node"
                        subtitle="Learn how to setup a node"
                        icon={<FontAwesomeIcon icon={faComputer} fontSize="20px" />}
                        rows={nodeResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Programming"
                        subtitle="About BTC programming"
                        icon={<FontAwesomeIcon icon={faCode} fontSize="20px" />}
                        rows={programmingResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Mining"
                        subtitle="Bitcoin mining resources"
                        icon={<FontAwesomeIcon icon={faMicrochip} fontSize="20px" />}
                        rows={miningResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="BIPs"
                        subtitle="List of Bitcoin BIPs"
                        icon={<FontAwesomeIcon icon={faCodePullRequest} fontSize="20px" />}
                        rows={bipsResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Education"
                        subtitle="Informative BTC resources"
                        icon={<FontAwesomeIcon icon={faGraduationCap} fontSize="20px" />}
                        rows={educationResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Trading"
                        subtitle="Bitcoin trading resources"
                        icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                        rows={tradingResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Investing"
                        subtitle="Learn about investing"
                        icon={<FontAwesomeIcon icon={faMoneyBills} fontSize="20px" />}
                        rows={investingResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Legal"
                        subtitle="Bitcoin legal links"
                        icon={<FontAwesomeIcon icon={faLegal} fontSize="20px" />}
                        rows={legalResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Bullishness"
                        subtitle="Reasons to be bullish"
                        icon={<FontAwesomeIcon icon={faSmile} fontSize="20px" />}
                        rows={bullishnessResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Game Theory"
                        subtitle="Bitcoin's game theory"
                        icon={<FontAwesomeIcon icon={faDiceD20} fontSize="20px" />}
                        rows={gameTheoryResourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

            </Flex>

            {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], false) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <WordTile
                            title="What is Bitcoin?"
                            subtitle="A peer-to-peer electronic cash system"
                            icon={<FontAwesomeIcon icon={faCircleQuestion} fontSize="20px" />}
                            body="Bitcoin is a unique, decentralized, peer-to-peer transaction network featuring a finite, programmable native digital currency. Bitcoin (BTC), the intrinsic asset of the Bitcoin blockchain, operates autonomously, free from central authorities or administrators. The Bitcoin network's stability is achieved through the collaborative efforts of full nodes, miners, and developers. Its foundation lies in a user-established and user-agreed social contract, which is strengthened by the strategic use of game theory and sophisticated cryptography. Bitcoin's design ensures censorship-resistance, permissionless access, and a reliable store of value, making it an innovative and secure form of digital money."
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 0 20%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <ImageTile
                            src="https://timechainstats.com/img/img-11d.png"
                            width="240"
                            height="240"
                            link="https://bitcoincore.org"
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <QuoteTile
                            title="Satoshi Nakamoto Said..."
                            subtitle="Random quote from the creator(s) of Bitcoin"
                            icon={<FontAwesomeIcon icon={faUserSecret} fontSize="20px" />}
                        />
                    </Flex>
                </Flex>
            )}

            {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], false) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <DataTable
                        title="What is a Sat Worth?"
                        subtitle="How many USD can a sat buy?"
                        icon={<FontAwesomeIcon icon={faRightLeft} fontSize="20px" />}
                        rows={whatIsASatWorthData}
                        animate={animateLastBlock}
                    />

                    <DataTable
                        title="What is a USD Worth?"
                        subtitle="How much bitcoin can a dollar buy?"
                        icon={<FontAwesomeIcon icon={faDollar} fontSize="20px" />}
                        rows={whatIsAUsdWorthData}
                        animate={animateLastBlock}
                    />

                    <DataTable
                        title="Bitcoin Breakdown"
                        subtitle="Dividing Bitcoin's total supply by group"
                        icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                        rows={bitcoinBreakdownData}
                        animate={animateLastBlock}
                    />
                </Flex>
            )}


            {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >TOP RESOURCES</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="FAQ"
                        subtitle="Frequently asked questions about BTC"
                        icon={<FontAwesomeIcon icon={faQuestionCircle} fontSize="20px" />}
                        rows={faqData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Reading"
                        subtitle="Learn more about Bitcoin"
                        icon={<FontAwesomeIcon icon={faBook} fontSize="20px" />}
                        rows={readingData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Resources"
                        subtitle="Helpful Bitcoin websites"
                        icon={<FontAwesomeIcon icon={faUpRightFromSquare} fontSize="20px" />}
                        rows={resourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Bitcoin Fixes This"
                        subtitle="Problems solved by BTC via BitcoinFixesThis.com"
                        icon={<FontAwesomeIcon icon={faWrench} fontSize="20px" />}
                        rows={bitcoinFixesThisData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Wallets"
                        subtitle="How to store your Bitcoin safely"
                        icon={<FontAwesomeIcon icon={faKey} fontSize="20px" />}
                        rows={walletsData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Bitcoin Visualizations"
                        subtitle="Visual representations of BTC data"
                        icon={<FontAwesomeIcon icon={faEye} fontSize="20px" />}
                        rows={visualizationsData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >
                {filterContent(['Tv'], false) && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 0 100%' }}
                    >
                        <TradingViewChart2
                            title="Stocks vs. Bitcoin"
                            subtitle="Stock performance vs BTC on a 1:1 basis"
                            icon={<FontAwesomeIcon icon={faMedal} fontSize="20px" />}
                        />
                    </Flex>
                )}
            </Flex>

            <BitcoinDivider color="orange.500" />

        </ContentWrapper>

    )
}
