import { useEffect, useRef } from "react";
import { Flex, Box, Text, useColorMode } from "@chakra-ui/react";
import { formatNumber, formatLargeNumber } from "../../utils";
import { recentBlocksState } from "../../recoilState";
import { useRecoilValue } from "recoil";
import { format, formatDistanceToNow } from "date-fns";


export const RecentBlocks = ({
    animate,
}) => {
    const scrollRef = useRef(null)
    const recentBlocks = useRecoilValue(recentBlocksState);
    const { colorMode } = useColorMode();
    const boxShadow = colorMode === 'dark' ? '0 1px 3px rgba(0, 0, 0, 0.12)' : '0 1px 2px rgba(0, 0, 0, 0.24)';
    const altTextColor = colorMode === 'dark' ? '#ffffff' : '#121212';
    const boxColor = colorMode === 'dark' ? 'primary.300' : '#ffffff';
    const latestBlockColor = colorMode === 'dark' ? "orange.500" : "orange.500";
    const latestBlockTextColor = colorMode === 'dark' ? "white" : "white";
    const emptyBlockTransactionCount = 1;


    //     const firstBlockTimestamp = recentBlocks[recentBlocks.length - 6].timestamp;

    // const getTimeSinceFirstBlock = () => {
    //     const currentTime = new Date().getTime() / 1000;
    //     const timeSinceBlock = currentTime - firstBlockTimestamp;
    //     return Math.floor(timeSinceBlock / 60);
    // };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return format(date, 'h:mm:ss a')
    };

    const getTimeSinceBlock = (blockTimestamp) => {
        const date = new Date(blockTimestamp * 1000);
        return formatDistanceToNow(date)
    };

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
        }
    }, [scrollRef])

    return (
        <Flex
            ref={scrollRef}
            width="full"
            justifyContent="space-between"
            flexDirection="row"
            gap="10px"
            mb={'12px'}
            overflowX={{ base: 'auto', md: 'hidden' }}
        >
            <Flex
                minW="100%"
                gap={3}
            >
                {recentBlocks.map((block, index) => {
                    const { id, height, tx_count, extras, size, timestamp } = block;
                    const isLatestFiveBlocks = index < 5;
                    const blockHeightColor = isLatestFiveBlocks
                        ? colorMode === "dark"
                            ? "orange.500"
                            : "blue.500"
                        : 'white';
                    const bg = tx_count === emptyBlockTransactionCount
                        ? 'transparent'
                        : index < 5
                            ? boxColor
                            : latestBlockColor
                    const color = tx_count === emptyBlockTransactionCount
                        ? 'orange.500'
                        : index < 5
                            ? altTextColor
                            : latestBlockTextColor
                    const outline = tx_count === emptyBlockTransactionCount
                        ? '6px solid #f7931a'
                        : 'none'
                    const textColor = tx_count === emptyBlockTransactionCount
                        ? 'orange.500'
                        : blockHeightColor

                    return (
                        <Box
                            minW={{base: "269px", md: "269px"}}
                            bg={bg}
                            color={color}
                            outline={outline}
                            outlineOffset={'-6px'}
                            borderRadius="3px"
                            flexGrow={1}
                            flexShrink={0}
                            textAlign={'center'}
                            boxShadow={boxShadow}
                            pt={'15px'}
                            pb={'18px'}
                            px="20px"
                            key={id}
                            onClick={() => window.open(`https://mempool.space/block/${id}`, '_blank')}
                            cursor={'pointer'}
                            _hover={{
                                opacity: 0.7,
                                transition: "opacity 0.25s ease-in-out"
                            }}
                            className={animate ? 'gray-border-custom gray-border' : ''}
                        >
                            <Text mb={2} fontSize={16} fontWeight="bold" color={textColor}>{height}</Text>
                            <Text fontSize={14} fontWeight="bold">{formatLargeNumber(size, 2, true)}B • {formatNumber(tx_count, 0)} TX</Text>
                            {
                                tx_count === emptyBlockTransactionCount
                                    ? <Text mb={2} fontWeight="bold" fontSize={'12px'}>
                                        EMPTY BLOCK
                                    </Text>
                                    : <>
                                    <Text mb={0} fontSize={'13px'}>Avg. Fee {formatNumber(extras.avgFeeRate, 0)} sats/vB</Text>
                                    <Text mb={2} fontSize={'11px'}>Fee Range {formatNumber(extras.feeRange[0], 0)} - {formatNumber(extras.feeRange[6], 0)} Sats</Text>
                                </>
                            }
                            <Text fontSize={'13px'}>Found @ {formatTimestamp(timestamp)}</Text>
                            <Text mb={2} fontSize={'11px'}>{getTimeSinceBlock(timestamp)} ago</Text>
                            <Text fontSize={'13px'} fontWeight="bold">{extras.pool.name}</Text>
                        </Box>
                    );
                })}
            </Flex>


            {/* <Text>~{formatNumber(getTimeSinceFirstBlock(), 0)} minutes</Text> */}
        </Flex>

    );
};
