import { SingleDataValue } from '../data-display';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const DataTileLeftToMine = ({ data, formatNumber, animate }) => {
  const leftToMine = data
    ? formatNumber(21000000 - data.circulating, 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="# Left to Mine" value={leftToMine} />
    </ThemedFlex>
  );
};
