import {
  Button,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";

export const LearnMoreButton = ({
  link,
  linkLabel = "Learn More",
  icon,
  showIcon = false,
}) => {
  const { colorMode } = useColorMode();

  const hoverBgColor = colorMode === "dark" ? "orange.500" : "blue.500";
  const hoverBorderColor = colorMode === "dark" ? "orange.500" : "blue.500";
  const hoverColor = colorMode === "dark" ? "orange.100" : "blue.100";

  return (
    <Button
      variant="outline"
      size="xs"
      mt={0}
      minW="90px"
      borderRadius="3px"
      color="gray.200"
      fontSize="10px"
      textTransform="uppercase"
      _hover={{
        bg: hoverBgColor,
        borderColor: hoverBorderColor,
        color: hoverColor,
      }}
    >
      <Link
        href={link}
        isExternal
        _hover={{
          textDecoration: "none",
        }}
      >
        {linkLabel}
        {showIcon && icon && (
          <Box as={icon.type} {...icon.props} marginLeft="5px" />
        )}
      </Link>
    </Button>
  );
};
