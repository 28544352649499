import { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { Select, Box } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const HashRateTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
}) => {
  const [chartData, setChartData] = useState([]);
  const [latestHashRate, setLatestHashRate] = useState(null);
  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data) {
      const allData = data.hashrate.hashrates.map(({ timestamp, avgHashrate }) => ({
        x: new Date(timestamp * 1000),
        y: avgHashrate / 1e18,
      }));

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 180,
        '3m': 90,
        '7d': 7,
        '2d': 2,
      };

      const filteredData = allData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData(filteredData);
      setLatestHashRate(filteredData[filteredData.length - 1].y);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        annotations: {
          yaxis: [
            {
              y: latestHashRate,
              borderColor: '#FFA500',
              borderWidth: 0,
              fillColor: '#FFA500',
              label: {
                borderColor: '#FFA500',
                borderWidth: 2,
                style: {
                  color: '#000000',
                  background: '#FFA500',
                  fontWeight: 'bold',
                  fontSize: '13px',
                },
                text: `${latestHashRate.toFixed(0)} EH/s`,
                offsetY: 0,
                offsetX: 5,
                textAnchor: 'start',
              },
            },
          ],
        },
        grid: {
          borderColor: '#555555',
          position: 'back',
        },
        series: [
          {
            name: 'Bitcoin Total Hash Rate',
            data: chartData,
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'Total Hash Rate',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: function (value) {
                return value.toFixed(0) + ' EH/s';
              },
              style: {
                colors: '#999',
              },
            },
          },
        ],
        colors: ['#ffa500'],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
        },
      };
      const chart = new ApexCharts(
        document.querySelector('#chart5'),
        options
      );
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);


      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, latestHashRate]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="100px"
          mb={0}
        >
          <option value="3y">3 Years</option>
          <option value="2y">2 Years</option>
          <option value="1y">1 Year</option>
          <option value="6m">6 Months</option>
          <option value="3m">3 Months</option>
          <option value="7d">7 Day</option>
          <option value="2d">2 Day</option>
        </Select>
        <Box flexGrow={1} minHeight='337px'>
          <div id="chart5" style={{ height: '100%' }} />
        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  };
}
